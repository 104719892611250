import {SupportedColorScheme} from '@mui/material';
import {ChartCode} from './types';
import {pattern} from './utils';

type BaseTooltipProps = {
    key: string;
    index: number;
    name: any;
    value: any;
    color: any;
    patternDataUrl?: string;
};

const BaseTooltip = ({key, index, name, value, color, patternDataUrl}: BaseTooltipProps) => {
    return (
        <li
            key={key}
            className='g2-tooltip-list-item'
            data-index={index}
            style={{
                marginBottom: 4,
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <div
                className='g2-tooltip-marker'
                style={{
                    backgroundColor: color,
                    backgroundImage: `url(${patternDataUrl})`,
                }}
            ></div>
            <span
                style={{
                    display: 'inline-flex',
                    flex: 1,
                    justifyContent: 'space-between',
                }}
            >
                <span style={{marginRight: 16}}>{name}:</span>
                <span className='g2-tooltip-list-item-value'>{value}</span>
            </span>
        </li>
    );
};

type BarTooltipProps = {
    title: string;
    items: any[];
    code: ChartCode;
    colorScheme?: SupportedColorScheme | undefined;
};

const BarTooltip = ({title, items, code, colorScheme}: BarTooltipProps) => {
    return (
        <>
            <h5 style={{marginTop: 16, fontSize: '14px'}}>{title}</h5>
            <ul style={{paddingLeft: 0}}>
                {items?.map((item, index) => {
                    const patternCfg = pattern(
                        {groupField: item.data.groupField},
                        item.color,
                        code,
                        colorScheme,
                    );
                    const {name, value, color} = item;

                    if (!patternCfg || patternCfg.cfg) {
                        return (
                            <BaseTooltip
                                key={`${item.data.groupField}_${index}`}
                                index={index}
                                name={name}
                                value={value}
                                color={color}
                            />
                        );
                    }

                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    const width = 30;
                    const height = 30;

                    if (ctx) {
                        canvas.width = width;
                        canvas.height = height;
                        ctx.fillStyle = patternCfg;
                        ctx.fillRect(0, 0, width, height);
                    }
                    const patternDataUrl = canvas.toDataURL();

                    return (
                        <BaseTooltip
                            key={`${item.data.groupField}_${index}`}
                            index={index}
                            name={name}
                            value={value}
                            color={color}
                            patternDataUrl={patternDataUrl}
                        />
                    );
                })}
            </ul>
        </>
    );
};

export const getBarTooltip = (
    title: string,
    items: any[],
    code: ChartCode,
    colorScheme?: SupportedColorScheme,
) => {
    return (
        <BarTooltip
            title={title}
            items={items}
            code={code}
            colorScheme={colorScheme}
        />
    );
};
