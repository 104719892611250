import {TableHead as TableHeadMui, TableRow} from '@mui/material';
import {TableHeadProps} from './types';
import {TableHeadActions} from './TableHeadActions';
import {TableHeadCell} from './TableHeadCell';

const TableHead = ({
    onSelectAllClick,
    columns,
    sortConfig,
    selected = [],
    rowCount = 0,
    totalElements = 0,
    tableConfig,
    onRequestSort,
    disableAction,
    onDelete,
    onAdd,
    onDeleteClick,
    confirmDeletingDescription,
    minHeight = '40px',
    cellClassName,
    filterConfig,
    setFilterConfig,
    renderAdditionalHeaderActions,
}: TableHeadProps) => {
    const {sort, withOrders} = tableConfig;

    return (
        <TableHeadMui>
            <TableRow sx={{minHeight}}>
                <TableHeadActions
                    selected={selected}
                    onSelectAllClick={onSelectAllClick}
                    rowCount={rowCount}
                    totalElements={totalElements}
                    tableConfig={tableConfig}
                    disableAction={disableAction}
                    onDelete={onDelete}
                    onAdd={onAdd}
                    onDeleteClick={onDeleteClick}
                    confirmDeletingDescription={confirmDeletingDescription}
                    cellClassName={cellClassName}
                    renderAdditionalHeaderActions={renderAdditionalHeaderActions}
                />
                {Object.values(columns).map((column, index) => (
                    <TableHeadCell
                        key={column.id}
                        column={column}
                        sortConfig={sortConfig}
                        onRequestSort={onRequestSort}
                        isSortable={sort.changeable}
                        disableAction={disableAction}
                        withOrders={withOrders}
                        minHeight={minHeight}
                        cellClassName={cellClassName}
                        isLastCell={index === Object.values(columns).length - 1}
                        setFilterConfig={setFilterConfig}
                        filterConfig={filterConfig}
                    />
                ))}
            </TableRow>
        </TableHeadMui>
    );
};

export default TableHead;
