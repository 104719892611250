import {useCallback, useMemo} from 'react';
import {useWells} from '@ui/WellsTree/hooks/useWells';
import {Loader} from '@ui/Loader/Loader';
import {PadsTreeMultiple} from '../PadsTreeMultiple/PadsTreeMultiple';
import {getPadsTree} from '../PadsTreeMultiple/utils';

type AccountModalPadsTreeProps = {
    selectedPads: Set<number>;
    setSelectedPads: (pads: Set<number>) => void;
};
export const AccountModalPadsTree = ({
    selectedPads,
    setSelectedPads,
}: AccountModalPadsTreeProps) => {
    const toggleManyPads = useCallback(
        (ids: number[], selected: boolean) => {
            ids.forEach((id) => {
                if (selected) {
                    selectedPads.add(id);
                } else {
                    selectedPads.delete(id);
                }
            });
            const newSelectedPads = new Set(selectedPads);
            setSelectedPads(newSelectedPads);
        },
        [selectedPads, setSelectedPads],
    );

    const {wells} = useWells();

    const tree = useMemo(() => {
        if (!wells) {
            return null;
        }
        return getPadsTree(wells);
    }, [wells]);

    return tree ? (
        <PadsTreeMultiple
            toggleManyPads={toggleManyPads}
            selectedPads={selectedPads}
            tree={tree}
        />
    ) : (
        <Loader />
    );
};
