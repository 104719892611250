import {ColumnRecord} from '@ui/NewTable/types';
import {
    operationStatusColors,
    operationStatusLabels,
    rolesColors,
    rolesLables,
    userStatusColors,
    userStatusLables,
} from './constants';
import {OperationStatus, Roles, UserStatus} from './types';
import {Chip, Typography} from '@mui/material';

export const formateAccountsColumns = (columns: ColumnRecord) => {
    const newColumns = {...columns};
    if (newColumns['role']) {
        const getFilterLabel = (option: string) => rolesLables[option as Roles];
        newColumns['role'].getFilterLabel = getFilterLabel;
        newColumns['role'].customDisplayFormat = (_rowId: any, value: string) => {
            return (
                <Chip
                    sx={{
                        color: rolesColors[value as Roles],
                        borderColor: rolesColors[value as Roles],
                        borderRadius: '16px',
                    }}
                    label={rolesLables[value as Roles]}
                    variant='outlined'
                />
            );
        };
    }
    if (newColumns['status']) {
        const getFilterLabel = (option: string) => userStatusLables[option as UserStatus];
        newColumns['status'].getFilterLabel = getFilterLabel;
        newColumns['status'].customDisplayFormat = (_rowId: any, value: string) => {
            return (
                <Typography
                    sx={{fontSize: '14px'}}
                    color={userStatusColors[value as UserStatus]}
                >
                    {userStatusLables[value as UserStatus]}
                </Typography>
            );
        };
    }
    if (newColumns['operationStatus']) {
        newColumns['operationStatus'].customDisplayFormat = (_rowId: any, value: string) => {
            return value ? (
                <Typography
                    sx={{fontSize: '14px'}}
                    color={operationStatusColors[value as OperationStatus]}
                >
                    {operationStatusLabels[value as OperationStatus]}
                </Typography>
            ) : (
                ''
            );
        };
    }
    return newColumns;
};
