import {Key} from 'react';
import api from '../../utils/api';
import {ParamsType} from '../../utils';
import {
    WellEntity,
    WellEntityToUpdate,
    WellEntityToUpdateWithoutId,
} from '../../pages/WellsPage/types';
import {GetFieldsResponse, PadWithWells} from '@features/fields/types';
import {WellPadWithMonitoringData} from '@core/api/back/models';

export class WellsService {
    private static instance: WellsService;

    public static getInstance() {
        if (!WellsService.instance) {
            WellsService.instance = new WellsService();
        }

        return WellsService.instance;
    }

    private constructor() {}

    public getWells(params: ParamsType): Promise<WellEntity[]> {
        return api.get(`/wells`, params);
    }

    public getWellById(id: number | string | undefined): Promise<WellEntity> {
        return api.get(`/wells/${id}`);
    }

    public createWell(data: WellEntityToUpdateWithoutId): Promise<void> {
        return api.post(`/wells`, data);
    }

    public createWellInPad(
        padId: string | number,
        data: WellEntityToUpdateWithoutId,
    ): Promise<unknown> {
        return api.post(
            `/wells`,
            data,
            {},
            {
                padId,
            },
        );
    }

    public updateWell(data: WellEntityToUpdate): Promise<void> {
        return api.put(`/wells/${data.id}`, data);
    }

    public deleteWell(id: string | number): Promise<void> {
        return api.delete(`/wells/${id}`);
    }

    public bulkDeleteWells(ids: Key[]): Promise<void> {
        return api.delete(`/wells`, null, {
            params: {
                id: ids,
            },
        });
    }

    public getWellsByField(field: string): Promise<WellEntity[]> {
        return api.get('/wells/titlesByField', {
            field,
        });
    }

    public getTablesToCopy(): Promise<string[]> {
        return api.get(`/wells/allowed-tables-to-copy`);
    }

    public loadWellFields() {
        return api.get<GetFieldsResponse>('/well-fields').then((result) =>
            result.map((field) => ({
                ...field,
                pads: field.pads.map((pad) => ({
                    ...pad,
                    fieldId: field.id,
                })),
            })),
        );
    }

    public searchWellFields({
        wellprofile,
        status,
        search,
    }: {
        wellprofile: string[] | undefined;
        status: string[] | undefined;
        search: string;
    }) {
        return api.get<WellEntity[]>('/wells', {
            wellprofile: wellprofile,
            status: status,
            search,
        });
    }

    public loadWellPads(fieldId: string | number) {
        return api.get<PadWithWells[]>(
            '/well-pads/find-by-field',
            {
                fieldId,
            },
            {
                withoutGlobalLoader: true,
            },
        );
    }

    public loadMonitoringWellPads(fieldId: string | number, onlyWithConnections: boolean) {
        return api.get<WellPadWithMonitoringData[]>(
            '/monitoring/well-pads',
            {
                fieldId,
                onlyWithConnections,
            },
            {
                withoutGlobalLoader: true,
            },
        );
    }

    public searchMonitoringWellFields({
        wellprofile,
        status,
        search,
        onlyWithConnections,
    }: {
        wellprofile: string[] | undefined;
        status: string[] | undefined;
        search: string;
        onlyWithConnections: boolean;
    }) {
        return api.get<WellEntity[]>('/monitoring/wells', {
            wellprofile: wellprofile,
            status: status,
            search,
            onlyWithConnections,
        });
    }
}
