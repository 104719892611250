import {FC, PropsWithChildren, useCallback, useEffect, useMemo, useState} from 'react';
import {IconButton, Stack, useColorScheme} from '@mui/material';
import {ChartCode, ChartComponentProps, ChartFilter, ChartItem, ChartType} from './types';
import {
    chartHasData,
    checkShowEmptyValue,
    getChartByType,
    getChartData,
    getChartsConfig,
} from './utils';
import {Box, Typography} from '@mui/material';
import {useChart} from './useChart';
import ChartFilters from './ChartFilters';
import {FlexLoader} from '@ui/Loader/FlexLoader';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import cn from 'classnames';
import {convertChartData} from '@pages/CommonInfoPage/components/WellCommonInfo/Donuts/utils';
import {DonutLegend} from '@ui/Donut/Legend/DonutLegend';
import {ReactComponent as ZoomOutOutlinedIcon} from '../../assets/icons/zoomOut.svg';
import {DonutLegendByService} from '@ui/Donut/LegendByServise/DonutLegendByService';
import {useDeviceInfo} from 'utils/hooks/useDeviceInfo';

import styles from './Chart.module.scss';

type WithNaviveScrollProps = {
    needYScroll: boolean;
    needXScroll: boolean;
    getChartHeight: () => number | void;
    getChartWidth: () => number | void;
} & PropsWithChildren;

const WithNaviveScroll: FC<WithNaviveScrollProps> = ({
    children,
    getChartHeight,
    getChartWidth,
    needYScroll = false,
    needXScroll = true,
}) => {
    if (!needYScroll && !needXScroll) {
        return children;
    } else {
        const height = getChartHeight();
        const width = getChartWidth();

        return (
            <div
                className={cn(styles.scrollWrapper, {
                    [styles.yScroll]: needYScroll,
                    [styles.xScroll]: needXScroll,
                })}
            >
                <div
                    style={{
                        height: height ? `${getChartHeight()}px` : 'auto',
                        width: width ? `${getChartWidth()}px` : 'auto',
                    }}
                >
                    {children}
                </div>
            </div>
        );
    }
};

export function getDefaultFilters(chart: ChartItem) {
    const filterred = chart.params.filter(
        (el) => el.code !== 'wellId' && el.defaultValues.length !== 0,
    );
    return filterred.reduce((acc: Record<string, string[]>, curr) => {
        acc[curr.code] = curr.defaultValues;
        return acc;
    }, {});
}

const Chart = ({
    chart,
    wellId,
    withFilters = true,
    customConfig,
    customChartProps,
    hideTitle = false,
    forceReload,
    outerFilters,
    fullscreen,
    outerKey,
    setData,
    titleFontSize,
}: ChartComponentProps) => {
    const [key, setKey] = useState(0); // Используется для форсирования перерисовки
    const {colorScheme} = useColorScheme();
    const deviceInfo = useDeviceInfo();

    const [filters, setFilters] = useState<ChartFilter>(outerFilters || getDefaultFilters(chart));
    const [chartData, loading] = useChart({code: chart.code, filters, wellId, forceReload});

    const {Component, config, data} = useMemo(() => {
        if (chartData) {
            const data = getChartData(chart, chartData);

            return {
                Component: getChartByType(chart.type),
                data,
                config: getChartsConfig(
                    chart,
                    chartData,
                    colorScheme,
                    customConfig,
                    fullscreen?.isFullscreen,
                    deviceInfo,
                ),
            };
        }

        return {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chart, chartData, colorScheme, customConfig, fullscreen?.isFullscreen, key, deviceInfo]);

    const hasData = useMemo(() => {
        if (chartData && chart) {
            return chartHasData(chart, chartData);
        }
        return false;
    }, [chart, chartData]);

    useEffect(() => {
        if (chartData && setData) {
            setData(chartData);
        }
    }, [chartData, setData]);

    const showEmptyValue = useMemo(() => {
        return checkShowEmptyValue(chart, hasData);
    }, [chart, hasData]);

    const getChartHeight = useCallback(() => {
        const codes = [
            ChartCode.DRILLING_CHEMICAL_REAGENTS_CONSUMPTIONS,
            ChartCode.FASTENING_CHEMICAL_REAGENTS_CONSUMPTIONS,
            ChartCode.DEVELOPMENT_CHEMICAL_REAGENTS_CONSUMPTIONS,
            ChartCode.MTR_CONSUMPTIONS,
        ];

        if (codes.includes(chart.code)) {
            return chartData?.length ? 100 + chartData.length * 90 : undefined;
        }
    }, [chart, chartData]);

    const getChartWidth = useCallback(() => {
        if (
            chart.code === ChartCode.DAYS_COUNT_BY_1000M ||
            chart.code === ChartCode.PENETRATION_SPEED_INFO_BY_WELL
        ) {
            if (chartData) {
                return chartData?.length > 10 ? 100 + chartData.length * 30 : undefined;
            }
        }
        if (chart.code === ChartCode.KNBK_PARAMETERS || chart.code === ChartCode.KNBK_SINKING) {
            if (chartData) {
                // @ts-ignore
                const groupsCount = new Set(chartData.map((el) => el.x)).size;
                return groupsCount > 5 ? 1200 + groupsCount * 180 : undefined;
            }
        }
    }, [chart, chartData]);

    const wellOperationsPvNpvData = useMemo(() => {
        if (chartData && chart.code === ChartCode.WELL_OPERATIONS_PV_NPV) {
            return convertChartData(chartData, chart.code, colorScheme);
        }
    }, [chart.code, chartData, colorScheme]);

    const costByService = useMemo(() => {
        if (chartData && chart.code === ChartCode.COST_BY_SERVICE) {
            return convertChartData(chartData, chart.code, colorScheme);
        }
    }, [chart.code, chartData, colorScheme]);

    const handleResetClick = () => {
        setKey(key + 1); // Обновление ключа для форсирования перерисовки
    };

    useEffect(() => {
        if (outerKey) {
            setKey(outerKey);
        }
    }, [outerKey]);

    useEffect(() => {
        if (outerFilters) {
            setFilters(outerFilters);
        } else {
            setFilters(getDefaultFilters(chart));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [outerFilters]);

    return (
        <>
            {!!fullscreen && (
                <Stack direction='row'>
                    {chart.type === ChartType.Line && (
                        <IconButton
                            sx={{
                                position: 'absolute',
                                right: '40px',
                                top: '0',
                            }}
                            onClick={handleResetClick}
                        >
                            <ZoomOutOutlinedIcon />
                        </IconButton>
                    )}
                    <IconButton
                        sx={{
                            position: 'absolute',
                            right: '0',
                            top: '0',
                            ...(fullscreen.buttonStyles || {}),
                        }}
                        onClick={(event: any) => {
                            event.stopPropagation();
                            event.preventDefault();
                            fullscreen.onClick();
                        }}
                    >
                        {fullscreen.isFullscreen ? <ZoomInMapIcon /> : <ZoomOutMapIcon />}
                    </IconButton>
                </Stack>
            )}
            {!hideTitle && (
                <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <Typography
                        variant='h3'
                        fontSize={titleFontSize}
                    >
                        {chart.title}
                    </Typography>
                    {chart.type === ChartType.Line &&
                        chart.code !== ChartCode.COST_BY_DAY &&
                        chart.code !== ChartCode.GEOLOGICAL_JOURNAL &&
                        !fullscreen && (
                            <Stack>
                                <IconButton onClick={handleResetClick}>
                                    <ZoomOutOutlinedIcon />
                                </IconButton>
                            </Stack>
                        )}
                </Stack>
            )}
            {withFilters && (
                <ChartFilters
                    chart={chart}
                    setFilters={setFilters}
                    filters={filters}
                />
            )}
            {!loading && !hasData && (
                <Box
                    component='div'
                    className={styles.emptyValue}
                >
                    {showEmptyValue}
                </Box>
            )}
            {loading && <FlexLoader />}
            {!loading && hasData && (
                <WithNaviveScroll
                    needYScroll={
                        chart.code === ChartCode.DRILLING_CHEMICAL_REAGENTS_CONSUMPTIONS ||
                        chart.code === ChartCode.FASTENING_CHEMICAL_REAGENTS_CONSUMPTIONS ||
                        chart.code === ChartCode.DEVELOPMENT_CHEMICAL_REAGENTS_CONSUMPTIONS ||
                        chart.code === ChartCode.MTR_CONSUMPTIONS ||
                        chart.code === ChartCode.BUILDING_DATES_BY_STAGE
                    }
                    needXScroll={
                        chart.code === ChartCode.DAYS_COUNT_BY_1000M ||
                        chart.code === ChartCode.PENETRATION_SPEED_INFO_BY_WELL ||
                        chart.code === ChartCode.KNBK_PARAMETERS ||
                        chart.code === ChartCode.KNBK_SINKING
                    }
                    getChartHeight={getChartHeight}
                    getChartWidth={getChartWidth}
                >
                    <div
                        className={cn(styles.componentContainer, {
                            [styles.charWithLegend]: wellOperationsPvNpvData,
                            [styles.costByServiceChartWithLegend]:
                                chart.code === ChartCode.COST_BY_SERVICE,
                        })}
                    >
                        {/* @ts-ignore */}
                        <Component
                            data={data}
                            {...config}
                            {...customChartProps}
                        />

                        {chart.code === ChartCode.WELL_OPERATIONS_PV_NPV &&
                            wellOperationsPvNpvData?.data && (
                                <DonutLegend
                                    hasTotal
                                    size={fullscreen?.isFullscreen ? 'large' : 'small'}
                                    unit='Сутки'
                                    values={wellOperationsPvNpvData.data}
                                    maxHeight='unset'
                                    width={fullscreen?.isFullscreen ? '300px' : '150px'}
                                    headerProps={{
                                        sx: {
                                            backgroundColor: 'unset',
                                        },
                                    }}
                                />
                            )}
                        {chart.code === ChartCode.COST_BY_SERVICE && costByService?.data && (
                            <DonutLegendByService
                                size={fullscreen?.isFullscreen ? 'large' : 'small'}
                                values={costByService.data}
                                maxHeight='unset'
                                width={fullscreen?.isFullscreen ? '300px' : '200px'}
                                headerProps={{
                                    sx: {
                                        backgroundColor: 'unset',
                                    },
                                }}
                            />
                        )}
                    </div>
                </WithNaviveScroll>
            )}
        </>
    );
};

export default Chart;
