import {Autocomplete, Box, Button, IconButton, Stack, TextField} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {AccountData, AccountDataWithPassword, Roles} from '../types';
import {PasswordField} from './PasswordFiled';
import {LightTooltip} from '@ui/LightTooltip/LightTooltip';
import {useEffect, useMemo, useState} from 'react';
import {ReactComponent as RenderPasswordIcon} from 'assets/icons/renderPassword.svg';
import {ReactComponent as RerenderPasswordIcon} from 'assets/icons/rerenderPassword.svg';
import {getRequiredAdornment} from 'utils/functions/getRequiredAdornment';
import {useDict} from 'utils/hooks/useDict';
import {defaultValues, renderNewPassword, validatePassword} from './utils';
import {useMutation} from '@tanstack/react-query';
import {AccountsService} from '@services/Accounts/AccountsService';
import {rolesLables} from '../constants';
import {AccountModalPadsTree} from './AccountModalPadsTree';

type AccountModalFormProps = {
    handleClose: (withRefetch?: boolean) => void;
    initialData?: AccountData;
    setOpenNotification: (data: AccountDataWithPassword) => void;
};

export const AccountModalForm = ({
    handleClose,
    initialData,
    setOpenNotification,
}: AccountModalFormProps) => {
    const defaultValuesForm = useMemo(
        () => ({
            ...defaultValues,
            ...(initialData || {}),
            phoneNumber: initialData?.phoneNumber || defaultValues.phoneNumber,
            email: initialData?.email || defaultValues.email,
        }),
        [initialData],
    );
    const {
        control,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: {isValid},
        clearErrors,
        trigger,
        getValues,
    } = useForm<AccountDataWithPassword>({
        defaultValues: defaultValuesForm,
        mode: 'onChange',
    });

    useEffect(() => {
        reset(defaultValuesForm);
    }, [defaultValuesForm, reset]);

    const watchRole = watch('role');
    const watchPassword = watch('password');
    const watchPermittedPadIds = watch('permittedPadIds');

    const {data: contractorData} = useDict({code: 'Contractor'});
    const contractorOptions = (contractorData || []).map((el) => el.value);

    const {data: roleData} = useDict({code: 'UserRoleDictEntity>default'});
    const roleOptions = (roleData || []).map((el) => el.value);

    const [passwordWasRendered, setPasswordWasRendered] = useState(false);

    const mutateCreateAccount = useMutation({
        mutationKey: ['updateAccount'],
        mutationFn: (values: AccountDataWithPassword) => AccountsService.createData(values),
        onSuccess: (newData: AccountData) => {
            const data = getValues();
            if (data.password) {
                setOpenNotification({...data, id: newData.id});
            }
            handleClose(true);
        },
    });

    const mutateUpdateAccount = useMutation({
        mutationKey: ['updateAccount'],
        mutationFn: (values: AccountDataWithPassword) =>
            AccountsService.updateData(values.id!, values),
        onSuccess: () => {
            const data = getValues();
            if (data.password) {
                setOpenNotification(data);
            }
            handleClose(true);
        },
    });

    const onSubmit = (data: AccountDataWithPassword) => {
        const formattedData = {
            ...data,
            phoneNumber: data?.phoneNumber === '+7' ? null : data?.phoneNumber,
            email: data?.email || null,
            password: data?.password || null,
            passwordConfirm: data?.passwordConfirm || null,
        };
        formattedData?.id
            ? mutateUpdateAccount.mutate(formattedData)
            : mutateCreateAccount.mutate(formattedData);
    };

    const handleRenderPassword = () => {
        setPasswordWasRendered(true);
        const newPassword = renderNewPassword();
        setValue('password', newPassword);
        setValue('passwordConfirm', newPassword);
        clearErrors('password');
        clearErrors('passwordConfirm');
    };

    const setSelectedPads = (pads: Set<number>) => {
        setValue('permittedPadIds', Array.from(pads));
    };

    const isCreateMode = !initialData;

    const getRoleOptionLabel = (role: string) => {
        return rolesLables[role as Roles];
    };

    useEffect(() => {
        // При изменении поля password триггерим валидацию для поля passwordConfirm
        trigger('passwordConfirm');
    }, [trigger, watchPassword]);

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            style={{height: '100%'}}
            autoComplete='off'
        >
            <Stack
                justifyContent='space-between'
                sx={{pt: 1, height: '100%'}}
            >
                <Stack gap={1.5}>
                    <Controller
                        control={control}
                        rules={{required: true}}
                        name='username'
                        render={({field}) => (
                            <TextField
                                {...field}
                                label='Имя пользователя'
                                variant='outlined'
                                size='small'
                                InputProps={{
                                    ...getRequiredAdornment({
                                        value: field.value,
                                        required: true,
                                    }),
                                }}
                            />
                        )}
                    />
                    <Stack
                        direction='row'
                        gap={1}
                    >
                        <Controller
                            control={control}
                            rules={{
                                required: isCreateMode,
                                validate: validatePassword,
                            }}
                            name='password'
                            render={({field, fieldState}) => (
                                <PasswordField
                                    {...field}
                                    label='Пароль'
                                    required={isCreateMode}
                                    error={
                                        !!fieldState.error && fieldState?.error?.type !== 'required'
                                    }
                                    helperText={fieldState.error?.message}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            rules={{
                                required: isCreateMode,
                                validate: (value) => {
                                    return value === watchPassword || 'Пароли не совпадают';
                                },
                            }}
                            name='passwordConfirm'
                            render={({field, fieldState}) => (
                                <PasswordField
                                    {...field}
                                    label='Подтвердить'
                                    required={isCreateMode}
                                    error={
                                        !!fieldState?.error &&
                                        fieldState?.error?.type !== 'required'
                                    }
                                    helperText={fieldState?.error?.message}
                                />
                            )}
                        />
                        <LightTooltip
                            enterDelay={500}
                            title={
                                passwordWasRendered
                                    ? 'Сгенерировать пароль повторно'
                                    : 'Сгенерировать пароль'
                            }
                        >
                            <IconButton
                                onClick={handleRenderPassword}
                                sx={{height: 'fit-content'}}
                            >
                                {passwordWasRendered ? (
                                    <RerenderPasswordIcon />
                                ) : (
                                    <RenderPasswordIcon />
                                )}
                            </IconButton>
                        </LightTooltip>
                    </Stack>
                    <Controller
                        control={control}
                        name='organization'
                        render={({field}) => (
                            <Autocomplete
                                {...field}
                                fullWidth
                                options={contractorOptions}
                                onChange={(_event, values) => {
                                    field.onChange(values);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        size='small'
                                        label='Организация'
                                        variant='outlined'
                                        InputProps={{
                                            ...params.InputProps,
                                            ...getRequiredAdornment({
                                                value: field.value,
                                                required: true,
                                                styles: {paddingLeft: '8px'},
                                            }),
                                        }}
                                    />
                                )}
                            />
                        )}
                    />
                    <Stack
                        gap={1}
                        direction='row'
                    >
                        <Controller
                            control={control}
                            name='phoneNumber'
                            rules={{
                                validate: (value) => {
                                    if (!value) return true;
                                    if (value === '+7') return true;
                                    return /^\+7\d{10}$/.test(value) || 'Некорректный формат';
                                },
                            }}
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    label='Телефон'
                                    variant='outlined'
                                    size='small'
                                    onChange={(e) => {
                                        const value = e.target.value.replace(/[^+\d]|(?!^)\+/g, '');
                                        // Запрещаем стирать +7
                                        if (!value.startsWith('+7')) {
                                            return;
                                        }
                                        field.onChange(value);
                                    }}
                                    error={!!fieldState?.error}
                                    helperText={fieldState?.error?.message}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name='email'
                            rules={{
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: 'Некорректный формат',
                                },
                            }}
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    label='Электронная почта'
                                    variant='outlined'
                                    type='email'
                                    size='small'
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                />
                            )}
                        />
                    </Stack>
                    <Controller
                        control={control}
                        name='role'
                        rules={{required: true}}
                        render={({field}) => (
                            <Autocomplete
                                {...field}
                                fullWidth
                                options={roleOptions}
                                onChange={(_event, values) => {
                                    field.onChange(values);
                                    setValue('permittedPadIds', null);
                                }}
                                getOptionLabel={getRoleOptionLabel}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        size='small'
                                        label='Доступ'
                                        variant='outlined'
                                        InputProps={{
                                            ...params.InputProps,
                                            ...getRequiredAdornment({
                                                value: field.value,
                                                required: true,
                                                styles: {paddingLeft: '8px'},
                                            }),
                                        }}
                                    />
                                )}
                            />
                        )}
                    />
                    {watchRole && watchRole === Roles.OPERATOR && (
                        <Box
                            component='div'
                            sx={{
                                borderRadius: 'var(--mui-shape-borderRadius)',
                                padding: '8px',
                                border: '1px solid rgba(var(--mui-palette-common-onBackgroundChannel) / 0.23)',
                                height: '225px',
                                overflow: 'auto',
                            }}
                        >
                            <AccountModalPadsTree
                                selectedPads={new Set(watchPermittedPadIds || [])}
                                setSelectedPads={setSelectedPads}
                            />
                        </Box>
                    )}
                </Stack>

                <Stack
                    direction='row'
                    justifyContent='flex-end'
                    gap={1}
                    sx={{padding: '12px 0'}}
                >
                    <Button
                        onClick={() => handleClose()}
                        variant='outlined'
                    >
                        Отмена
                    </Button>
                    <Button
                        variant='contained'
                        type='submit'
                        disabled={!isValid}
                    >
                        Сохранить
                    </Button>
                </Stack>
            </Stack>
        </form>
    );
};
