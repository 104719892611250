import {
    BarChartProps,
    ChartCode,
    ChartItem,
    ChartProps,
    ChartType,
    ColumnChartProps,
    LineChartItem,
    LineChartProps,
    PieChartItem,
    PieChartProps,
    RangeBarItem,
    RangeBarProps,
    ReflectConfig,
    WaterfallChartProps,
} from './types';
import {
    Bar,
    BarConfig,
    Column,
    ColumnConfig,
    getCanvasPattern,
    Line,
    LineConfig,
    Pie,
    PieConfig,
    Waterfall,
    WaterfallConfig,
} from '@ant-design/plots';

import {Annotation, G2, LineOptions as G2plotConfig} from '@antv/g2plot';
import {DT_FORMATS, formatDate} from '../../utils';
import {deepMix} from '@antv/util';
import {SupportedColorScheme} from '@mui/material';
import {colors} from '@features/incident/constants';
import {costByServiceColors} from '@features/Cost/constants';
import {getBarTooltip} from './BarTooltip';
import {DeviceInfo} from 'utils/hooks/useDeviceInfo';

export const chartHasData = (chart: ChartItem, values: ChartProps): boolean => {
    if (chart.type === ChartType.Line) {
        return (values as LineChartProps).some((line) => Boolean(line.values.length));
    }

    return Boolean(values.length);
};

export const checkShowEmptyValue = (chart: ChartItem, chartHasData: boolean) => {
    if (!chartHasData && chart.code === ChartCode.DRILLING_CHEMICAL_REAGENTS_CONSUMPTIONS) {
        return 'Выберите Хим.реагенты и Обсадную колонну для отображения диаграммы';
    }
    if (!chartHasData && chart.code === ChartCode.MTR_CONSUMPTIONS) {
        return 'Выберите Наименование МТР и Обсадную колонну для отображения диаграммы';
    }
    return 'Нет данных';
};

export const splitStringToLines = (str: string, maxLength = 18) => {
    const parts = str.split(' ');
    const res = [];
    let accum = parts[0];
    for (let i = 1; i < parts.length; i++) {
        const word = parts[i];
        if (accum.length + word.length + 1 <= maxLength) {
            accum += ' ' + word;
        } else {
            res.push(accum);
            accum = word;
        }
    }
    if (accum) {
        res.push(accum);
    }
    return res.join(' \n');
};

export const getChartByType = (type: ChartType) => {
    switch (type) {
        case ChartType.Bar:
        case ChartType.RangeBar:
            return Bar;
        case ChartType.Column:
            return Column;
        case ChartType.Waterfall:
            return Waterfall;
        case ChartType.Pie:
            return Pie;
        case ChartType.Line:
        default:
            return Line;
    }
};

export const getChartsConfig = (
    chart: ChartItem,
    values: ChartProps,
    colorScheme: SupportedColorScheme | undefined,
    customConfig?: (
        config: Omit<G2plotConfig, 'tooltip' | 'data'>,
        values?: ChartProps,
    ) => G2plotConfig,
    isFullscreen?: boolean,
    deviceInfo?: DeviceInfo,
) => {
    const theme = G2.getTheme(colorScheme);
    const defaultConfig = {
        tooltip: {
            domStyles: {
                ...theme.components.tooltip.domStyles,
                'g2-tooltip-marker': {
                    width: '20px',
                    height: '20px',
                },
                'g2-tooltip-list-item': {
                    display: 'flex',
                    alignItems: 'center',
                },
                'g2-tooltip-title': {
                    fontSize: '14px',
                },
            },
        },
    };

    let config;

    switch (chart.type) {
        case ChartType.Bar:
            config = getBarConfig(chart, colorScheme, values as BarChartProps);
            break;
        case ChartType.RangeBar:
            config = getRangeBarConfig(values as RangeBarProps, colorScheme);
            break;
        case ChartType.Column:
            config = getColumnConfig(chart, colorScheme, values as ColumnChartProps, deviceInfo);
            break;
        case ChartType.Pie:
            config = getPieConfig(chart, colorScheme, isFullscreen, values as PieChartProps);
            break;
        case ChartType.Waterfall:
            config = getWaterfallConfig(colorScheme, values as WaterfallChartProps);
            break;
        case ChartType.Line:
        default:
            config = getLineConfig(chart, colorScheme);
            break;
    }
    config = combiningConfigs(defaultConfig, config);

    return customConfig ? customConfig(config, values) : config;
};

export const getRangeBarConfig = (
    props: RangeBarProps,
    colorScheme: SupportedColorScheme | undefined,
): Omit<BarConfig, 'data'> => {
    const [min, max] = getMainRange(props);

    return {
        xField: 'x',
        yField: 'groupName',
        seriesField: 'seriesName',
        isRange: true,
        maxBarWidth: 20,
        legend: {
            itemName: {
                style: {
                    fill: computeFill(colorScheme),
                },
            },
        },
        meta: {
            x: {
                type: 'time',
                min,
                max,
            },
        },
        label: {
            content: (data: unknown) => {
                return formatDate((data as RangeBarItem).x[0], DT_FORMATS.defaultDate) as string;
            },
            offsetY: -20,
            offsetX: -10,
            position: () => 'left',
            style: {
                fill: computeFill(colorScheme),
            },
        },
        tooltip: {
            formatter: (data: any) => {
                return {
                    name: data.seriesName as string,
                    value: `${formatDate(data.x[0], DT_FORMATS.defaultDate)} - ${formatDate(
                        data.x[1],
                        DT_FORMATS.defaultDate,
                    )}`,
                };
            },
            fields: ['seriesName', 'x', 'y', 'groupName'],
        },
        xAxis: {
            label: {
                formatter: (text) => {
                    return formatDate(text, DT_FORMATS.defaultDate) as string;
                },
                style: {
                    fill: computeFill(colorScheme),
                },
            },
        },
    };
};

const getMainRange = (data: RangeBarProps): [string, string] => {
    if (!data?.length) {
        return ['2020-01-01', new Date().toISOString()];
    }

    const firstItem = data[0] as RangeBarItem;
    const mainRange = firstItem.x.map((item) => new Date(item));

    data.forEach((item) => {
        const startDate = new Date(item.x[0]);
        const endDate = new Date(item.x[1]);

        if (startDate < mainRange[0]) {
            mainRange[0] = startDate;
        }

        if (endDate > mainRange[1]) {
            mainRange[1] = endDate;
        }
    });

    return mainRange.map((item) => formatDate(item, DT_FORMATS.utcDate) as string) as [
        string,
        string,
    ];
};

const getPatternConfig = (code: ChartCode, colorScheme?: SupportedColorScheme) => {
    if (code === ChartCode.COST_EXPENSE_STRUCTURE_BY_CASING_COLUMN) {
        return {
            План: null,
            Факт: {
                type: 'line',
                cfg: {
                    size: 5,
                    padding: 2,
                    rotation: 145,
                    isStagger: false,
                    stroke: colorScheme === 'light' ? '#000000' : '#FFFFFF',
                },
            },
        };
    }
    return {
        Проект: {
            type: 'line',
            cfg: {
                padding: 4,
            },
        },
        План: {
            type: 'square',
            cfg: {
                size: 5,
                padding: 2,
                rotation: 45,
                isStagger: false,
            },
        },
    };
};

type patternProps = {
    groupField?: 'План' | 'Проект';
};

export const pattern = (
    item: patternProps,
    color: string,
    code: ChartCode,
    colorScheme?: SupportedColorScheme,
) => {
    const patternOptions = deepMix(
        {},
        getPatternConfig(code, colorScheme)[item.groupField ?? 'План'],
        {
            cfg: {
                backgroundColor: color,
            },
        },
    );
    return getCanvasPattern(patternOptions) ?? patternOptions;
};

type Accumulator = {
    [key: string]: number;
};

const getAnnotations = (
    data: BarChartProps,
    code: ChartCode,
    colorScheme?: SupportedColorScheme,
) => {
    if (code === ChartCode.BUILDING_DATES_BY_STAGE) {
        const array = Object.entries(
            data.reduce((acc: Accumulator, cur) => {
                //groupField - план/факт/проект
                //x - обсадная колонна
                const x = splitStringToLines(cur.x);
                const key = `${x}-${cur.groupField}`;
                if (!acc[key]) acc[key] = 0;
                acc[key] += cur.y;
                return acc;
            }, {} as Accumulator),
        );
        const res = array.map(([key, total], index) => {
            const [, groupField] = key.split('-');
            return {
                type: 'text',
                content: `${groupField}: ${total.toFixed(2)}`,
                position: (_xScales, yScales) => {
                    const rowsPerSection = 3; // Каждая секция состоит из 3 строк: План, Факт и Проект
                    const totalHeight = 100; // 100% высота графика
                    const numberOfSections = array.length / rowsPerSection; // количество секций
                    const totalRows = numberOfSections * rowsPerSection; // всего строк
                    const totalGaps = numberOfSections * 2; // всего промежутков;

                    // высота промежутка относительно строки
                    const gapHeight = 0.8;
                    // высота строки
                    const elementHeight = totalHeight / (totalRows + totalGaps * gapHeight);
                    // высота секции
                    const sectionHeight =
                        elementHeight * rowsPerSection + elementHeight * gapHeight * 2;

                    function calculateYPosition(sectionIndex: number, groupField: string) {
                        // Промежутки перед текущей секцией
                        const gapCountBeforeSection = sectionIndex * sectionHeight;
                        // // Смещение внутри элемента на основе типа
                        let subelementOffset = 0;
                        switch (groupField) {
                            case 'Факт':
                                subelementOffset = 1;
                                break;
                            case 'План':
                                subelementOffset = 2;
                                break;
                            case 'Проект':
                                subelementOffset = 3;
                                break;
                        }
                        const position =
                            elementHeight * subelementOffset + gapCountBeforeSection + gapHeight;
                        return position;
                    }

                    const yPos = calculateYPosition(Math.floor(index / 3), groupField);

                    // 0,045 - смещение текста по x, 0.6 - смещение текста по y
                    return [
                        // @ts-ignore
                        `${(total / yScales.y.max + 0.045) * 100}%`, // потому что для этого типа графика меняется местами x и y
                        `${yPos + 0.6}%`,
                    ];
                },
                style: {
                    textAlign: 'center',
                    fontSize: 13,
                    fill: computeFill(colorScheme),
                },
            } as Annotation;
        });

        const maxValue = data.reduce((acc: number, cur) => {
            return cur.y > acc ? cur.y : acc;
        }, 0);

        return {
            xAxis: {
                max: maxValue + 20,
                grid: {line: {style: {strokeOpacity: 0.4}}},
            },
            annotations: res,
        };
    }
};

const getFields = (settings: ChartItem['settings'], code: ChartCode) => {
    if (
        code === ChartCode.BUILDING_DATES_BY_STAGE ||
        code === ChartCode.MTR_CONSUMPTIONS ||
        code === ChartCode.DRILLING_CHEMICAL_REAGENTS_CONSUMPTIONS ||
        code === ChartCode.FASTENING_CHEMICAL_REAGENTS_CONSUMPTIONS ||
        code === ChartCode.DEVELOPMENT_CHEMICAL_REAGENTS_CONSUMPTIONS
    ) {
        return {
            rawFields: ['valueType', 'groupField'],
            isStack: settings?.isStack,
            groupField: 'groupField',
            pattern: (item: patternProps, color: string) => pattern(item, color, code),
        };
    }

    return {};
};

const getLabel = (code: ChartCode): BarConfig['label'] => {
    if (code === ChartCode.MTR_CONSUMPTIONS) {
        return {
            position: 'middle',
            layout: [
                {
                    type: 'adjust-color',
                },
            ],
        };
    }

    return false;
};

const getTooltip = (code: ChartCode, colorScheme?: SupportedColorScheme) => {
    if (
        code === ChartCode.BUILDING_DATES_BY_STAGE ||
        code === ChartCode.MTR_CONSUMPTIONS ||
        code === ChartCode.DRILLING_CHEMICAL_REAGENTS_CONSUMPTIONS ||
        code === ChartCode.FASTENING_CHEMICAL_REAGENTS_CONSUMPTIONS ||
        code === ChartCode.DEVELOPMENT_CHEMICAL_REAGENTS_CONSUMPTIONS ||
        code === ChartCode.COST_EXPENSE_STRUCTURE_BY_CASING_COLUMN
    ) {
        return {
            shared: false,
            customContent: (title: string, items: unknown[]) =>
                getBarTooltip(title, items, code, colorScheme),
        };
    }

    return undefined;
};

export const getBarConfig = (
    {settings, code}: ChartItem,
    colorScheme: SupportedColorScheme | undefined,
    data: BarChartProps,
): Omit<BarConfig, 'data'> => {
    const config: Omit<BarConfig, 'data'> = {
        isGroup: settings?.isGroup,
        xField: 'y',
        yField: 'x',
        seriesField: 'valueType',
        legend: {
            position: 'top-left',
            offsetY: -8,
            itemName: {
                style: {
                    fill: computeFill(colorScheme),
                },
            },
        },
        xAxis: {
            grid: {line: {style: {strokeOpacity: 0.4}}},
        },
    };

    const annotations = getAnnotations(data, code, colorScheme);
    const fields = getFields(settings, code);
    const label = getLabel(code);
    const tooltip = getTooltip(code);

    return {
        ...config,
        ...annotations,
        ...fields,
        tooltip,
        label,
    };
};

const sumValuesByGroup = (data: ColumnChartProps) => {
    return data.reduce(
        (acc, cur) => {
            const key = `${cur.x}-${cur.groupField}`;
            if (!acc[key]) acc[key] = 0;
            acc[key] += cur.y;
            return acc;
        },
        {} as Record<string, number>,
    );
};

const maxValueByGroup = (data: ColumnChartProps) => {
    return Math.max(...Object.values(sumValuesByGroup(data)));
};

const calculateOffsetByType = (
    groupField: string,
    hasBothTypes: boolean,
    isTablet: boolean | undefined,
) => {
    if (!hasBothTypes) {
        return 0;
    }

    if (isTablet) {
        if (groupField === 'Факт') {
            return 41;
        } else if (groupField === 'План') {
            return -41;
        }
    } else {
        if (groupField === 'Факт') {
            return 12.5;
        } else if (groupField === 'План') {
            return -11;
        }
    }

    return 0;
};

const getDirectionTypesMap = (data: ColumnChartProps) => {
    return data.reduce(
        (acc, cur) => {
            const direction = cur.x;
            const type = cur.groupField;
            if (!acc[direction]) {
                acc[direction] = new Set();
            }
            acc[direction].add(type as string);
            return acc;
        },
        {} as Record<string, Set<string>>,
    );
};

const getColumnAnnotations = (
    data: ColumnChartProps,
    code: ChartCode,
    colorScheme?: SupportedColorScheme,
    isTablet?: boolean,
) => {
    if (code === ChartCode.COST_EXPENSE_STRUCTURE_BY_CASING_COLUMN) {
        const uniqueXValues = Array.from(new Set(data.map((d) => d.x)));
        const directionTypesMap = getDirectionTypesMap(data);
        const groupedTotals = Object.entries(sumValuesByGroup(data));

        return groupedTotals.map(([key, total]) => {
            const [x, groupField] = key.split('-');
            const xPosition = uniqueXValues.indexOf(x);

            const hasBothTypes = directionTypesMap[x] && directionTypesMap[x].size > 1;

            return {
                type: 'text',
                content: `${total.toFixed(1)}`,
                position: [xPosition, total + 1],
                style: {
                    textAlign: 'center',
                    fontSize: 12,
                    fill: computeFill(colorScheme),
                },
                offsetX: calculateOffsetByType(groupField, hasBothTypes, isTablet),
                offsetY: -10,
            };
        });
    }
    return {};
};

export const getColumnConfig = (
    {settings, code}: ChartItem,
    colorScheme: SupportedColorScheme | undefined,
    data: ColumnChartProps,
    deviceInfo: DeviceInfo | undefined,
): Omit<ColumnConfig, 'data'> => {
    const isTablet = deviceInfo?.isTablet;

    const config: Omit<ColumnConfig, 'data'> = {
        isGroup: settings?.isGroup,
        xField: 'x',
        yField: 'y',
        seriesField: settings?.isGroup ? 'valueType' : '',
        tooltip: {
            title: (title: string, datum: any) => {
                return datum.x.replace('\n', '');
            },
            shared: true,
        },
        legend: {
            itemName: {
                style: {
                    fill: computeFill(colorScheme),
                },
            },
        },
        meta: {
            y: {
                alias: 'Количество',
            },
        },
        yAxis: {
            grid: {line: {style: {strokeOpacity: 0.4}}},
        },
    };

    if (code === ChartCode.WASTE_GENERATION_ACCOUNTING_PLAN_FACT) {
        // @ts-ignore
        config.label = {
            position: 'top',
            style: {
                fill: computeFill(colorScheme),
            },
            //Поворачиваем текст и немного сдвигаем его, чтобы все влезло
            rotate: 80.1,
            offsetX: 10,
            offsetY: 7,
        };
    }

    if (code === ChartCode.STAGE_DURATION) {
        // @ts-ignore
        config.label = {
            position: 'top',
            style: {
                fill: computeFill(colorScheme),
            },
            //Поворачиваем текст и немного сдвигаем его, чтобы все влезло
            rotate: 80.1,
            offsetX: 8,
            offsetY: 5,
        };
        // @ts-ignore
        config.xAxis = {
            label: {
                //Поворачиваем текст и немного сдвигаем его, чтобы все влезло
                rotate: 80.1,
                offsetX: -53,
                offsetY: -10,
            },
        };
    }

    if (code === ChartCode.COST_EXPENSE_STRUCTURE_BY_CASING_COLUMN) {
        //@ts-ignore
        config.minColumnWidth = isTablet ? 80 : 22;

        //@ts-ignore
        config.maxColumnWidth = isTablet ? 80 : 22;

        //@ts-ignore
        config.dodgePadding = isTablet ? 6 : 3;

        //@ts-ignore
        config.xAxis = {
            label: {
                autoHide: false,
                autoRotate: false,
                style: {
                    fontSize: 10,
                    fill: computeFill(colorScheme),
                },
                formatter: (text: string) => {
                    const maxLength = 9;
                    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text; //нет встроенного textOverflow
                },
            },
            line: null,
            tickLine: null,
        };

        // @ts-ignore
        config.yAxis = {
            style: {
                fill: '#FFFFFF1F',
            },
            min: 0,
            max: maxValueByGroup(data) + 5,
            tickInterval: 20,
            label: {
                style: {
                    fill: computeFill(colorScheme),
                },
            },

            line: {
                style: {
                    strokeOpacity: computeStrokeOpacity(colorScheme),
                    stroke: computeAxisLineColor(colorScheme),
                    lineWidth: 1,
                },
            },

            grid: {
                line: {
                    style: {
                        strokeOpacity: computeStrokeOpacity(colorScheme),
                        fill: computeAxisLineColor(colorScheme),
                        lineWidth: 1,
                    },
                },
            },
        };

        config.tooltip = getTooltip(code, colorScheme);

        //@ts-ignore
        config.interactions = [
            {type: 'element-highlight', enable: false},
            {type: 'element-active', enable: false},
        ];

        //@ts-ignore
        config.label = {
            position: 'top',
            style: {
                fill: computeTextColor(colorScheme),
            },
            formatter: (d: {y: number}) => {
                const {y} = d;
                const value = `${y.toFixed(1)}`;

                if (y < 4) {
                    return '';
                }

                return value;
            },

            layout: [
                {
                    type: 'interval-adjust-position',
                },
            ],
        };

        //@ts-ignore
        config.isStack = true;

        //@ts-ignore
        config.isGroup = true;

        //@ts-ignore
        config.seriesField = 'valueType';

        //@ts-ignore
        config.groupField = 'groupField';

        //@ts-ignore
        config.legend = {
            position: 'top-left',
            itemName: {
                style: {
                    fill: computeFill(colorScheme),
                },
            },
            selected: {},
            radio: null,
        };

        //@ts-ignore
        config.interactions = [
            {type: 'legend-highlight', enable: false},
            {type: 'legend-filter', enable: false},
        ];
        //@ts-ignore
        config.color = (d: {valueType: string}) => {
            const {valueType} = d;

            if (valueType.includes('НПВ')) return '#D32F2F';
            if (valueType.includes('ПВ')) return '#4DB6AC';
            if (valueType.includes('ПНВ')) return '#EF6C00 ';
            return colors[3];
        };

        //@ts-ignore
        config.annotations = getColumnAnnotations(data, code, colorScheme, isTablet);

        //@ts-ignore
        config.columnWidthRatio = 0.75;

        //@ts-ignore
        config.pattern = (d: {y: number; valueType: string}) => {
            const matchedData = data.find(
                (item) => item.y === d.y && item.valueType === d.valueType,
            );

            if (matchedData?.groupField === 'Факт') {
                return {
                    type: 'line',
                    cfg: {
                        spacing: 25,
                        stroke: computeTextColor(colorScheme),
                        lineWidth: 2,
                        rotation: 145,
                    },
                };
            }

            return {};
        };
    }

    if (code === ChartCode.PENETRATION_SPEED_INFO_BY_WELL) {
        //@ts-ignore
        config.color = colors;
    }

    if (code === ChartCode.OT_PB_OOS_BY_WELLS) {
        // @ts-ignore
        config.color = ({x}) => colors[data.findIndex((val) => val.x === x)];
        // @ts-ignore
        //задаем formatter потому, что свойство meta.alias при задании color перестает работать
        config.tooltip.formatter = (datum) => ({
            name: 'Количество',
            value: datum.y,
        });
    }

    if (code === ChartCode.DAYS_COUNT_BY_1000M || code === ChartCode.COST_BY_SERVICE) {
        // @ts-ignore
        config.tooltip = {
            shared: false,
        };
    }

    if ([ChartCode.OT_PB_OOS_BY_WELL, ChartCode.OT_PB_OOS_BY_WELLS].includes(code)) {
        // @ts-ignore
        config.label = {
            position: 'top',
            style: {
                fill: '#FFF',
                opacity: 0.7,
                font: 'Roboto',
                size: '10px',
            },
        };
        //@ts-ignore
        config.meta.y = {...config.meta.y, max: Math.max(...data.map((d) => d.y)) * 1.1};
    }

    return config;
};

export const getPieConfig = (
    {code}: ChartItem,
    colorScheme: SupportedColorScheme | undefined,
    isFullscreen?: boolean,
    values?: PieChartProps,
): Omit<PieConfig, 'data'> => {
    const config = {
        angleField: 'value',
        colorField: 'name',
        label: {
            type: 'inner',
            // @ts-ignore
            content: (item) => item.y,
            style: {
                fill: computeFill(colorScheme),
            },
        },
        interactions: [
            {
                type: 'element-active',
            },
        ],
        legend: {
            itemName: {
                style: {
                    fill: computeFill(colorScheme),
                },
            },
        },
    };

    // Для графика с кодом WELL_OPERATIONS_PV_NPV задаем свои цвета
    if (code === ChartCode.WELL_OPERATIONS_PV_NPV) {
        // @ts-ignore
        config.color = (d: {name: string}) => {
            const {name} = d;

            if (name.includes('НПВ')) return colorScheme === 'light' ? '#D32F2F ' : '#D32F2F';
            if (name.includes('ПВ')) return colorScheme === 'light' ? '#4CAF50' : '#388E3C';
            if (name.includes('ПНВ')) return colorScheme === 'light' ? '#F57C00' : '#EF6C00';
            return colors[3];
        };
        config.label = {
            type: 'outer',
            labelHeight: 50,
            offset: 18,
            content: (item: {name: string; percent: number; value: number}) =>
                `${item.name} ${item.value.toFixed(1)} (${(item.percent * 100).toFixed(1)} %)`,
            style: {
                fill: computeFill(colorScheme),
                // @ts-ignore
                fontSize: isFullscreen ? 18 : 12,
            },
            labelLine: {
                style: {
                    stroke: computeStrokeColor(colorScheme),
                },
            },
        };
        // @ts-ignore
        config.legend = false;
    }

    if (code === ChartCode.COST_BY_SERVICE) {
        const colorMap = values?.reduce((acc: Record<string, string>, item, index) => {
            acc[item.name] = costByServiceColors[index % costByServiceColors.length];
            return acc;
        }, {});

        // @ts-ignore
        config.color = (d: {name: string}) => {
            const {name} = d;
            return colorMap?.[name] || costByServiceColors[0];
        };

        config.label = {
            type: 'outer',
            labelHeight: 20,
            offset: 18,
            content: (item: {name: string; percent: number; value: number}) =>
                `${(item.percent * 100).toFixed(1)}%, ${item.name}`,
            style: {
                fill: computeFill(colorScheme),
                // @ts-ignore
                fontSize: isFullscreen ? 18 : 12,
            },
            labelLine: {
                style: {
                    stroke: computeStrokeColor(colorScheme),
                },
            },
        };

        // @ts-ignore
        config.tooltip = {
            formatter: (item: {name: string; value: number; percent: number}) => {
                return {
                    name: item.name,
                    value: `${item.value.toFixed(3)} млн. руб`,
                };
            },
        };

        // @ts-ignore
        config.legend = false;

        // @ts-ignore
        config.radius = 0.7;

        // @ts-ignore
        config.statistic = {
            title: false,
            content: '',
        };

        // @ts-ignore
        config.innerRadius = 0.45;

        // @ts-ignore
        config.pieStyle = {
            stroke: colorScheme === 'light' ? '#ffffff' : '#2A2A2A',
            strokeWidth: 10,
        };
    }

    return config;
};

const prepareReflectConfig = (reflect?: ReflectConfig): LineConfig['reflect'] => {
    switch (reflect) {
        case ReflectConfig.XY:
            return ['x', 'y'];
        case ReflectConfig.X:
            return 'x';
        case ReflectConfig.Y:
            return 'y';
        default:
            return;
    }
};

const getLineConfig = (
    {code, settings}: ChartItem,
    colorScheme: SupportedColorScheme | undefined,
): Omit<LineConfig, 'data'> => {
    const isTime = [ChartCode.COST_BY_DAY, ChartCode.GEOLOGICAL_JOURNAL].some((v) => code === v);
    const config: Omit<LineConfig, 'data'> = {
        xField: 'x',
        yField: 'y',
        seriesField: 'type',
        meta: {
            x: {
                type: isTime ? 'time' : 'linear',
            },
        },
        tooltip: {
            title: (title: string, datum: any) => {
                if (isTime) {
                    if (code === ChartCode.COST_BY_DAY) {
                        return formatDate(datum.x, DT_FORMATS.defaultDate);
                    } else {
                        return formatDate(datum.x, DT_FORMATS.short);
                    }
                }
                return datum.x as string;
            },
            formatter: (item: any) => {
                return {name: item.type as string, value: item.y as string};
            },
        },
        legend: {
            itemName: {
                style: {
                    fill: computeFill(colorScheme),
                },
            },
            maxItemWidth: 300,
        },
        interactions: [
            {
                type: 'brush',
            },
        ],
        reflect: prepareReflectConfig(settings?.reflect),
        yAxis: {
            grid: {line: {style: {strokeOpacity: 0.4}}},
        },
    };
    // Для ГГД графика задаем свои цвета для линий т.к. в разных скважинах линии план и факт отображаются разными цветами
    if (code === ChartCode.GGD_FACT) {
        // @ts-ignore
        config.color = (d: {type: string}) => {
            const {type} = d;

            if (type.includes('Плановый забой')) return '#448AFF';
            if (type.includes('Фактический забой')) return '#4CAF50';
            if (type.includes('Отклонение от плана')) return '#7C4DFF';
            if (type.includes('ЛКС'))
                return colorScheme === 'dark' ? 'rgba(255, 255, 255, 0.70)' : 'rgba(0, 0, 0, 0.60)';
            return '#7C4DFF';
        };
    }

    if (code === ChartCode.COST_BY_DAY) {
        // @ts-ignore
        config.yAxis = {
            title: {
                text: '',
                position: 'center',
            },
            grid: {
                line: {
                    style: {
                        strokeOpacity: computeStrokeOpacity(colorScheme),
                        fill: computeAxisLineColor(colorScheme),
                    },
                },
            },
        };

        // @ts-ignore
        config.interactions = [
            {type: 'chart:zoom', enable: false},
            {type: 'chart:pan', enable: false},
        ];
        // @ts-ignore
        config.color = ({type}: {type: string}) => {
            if (type.includes('Факт')) {
                return '#009688';
            }

            return '#2963C5';
        };

        // @ts-ignore
        config.xAxis = {
            grid: {
                line: {
                    style: {
                        strokeOpacity: computeStrokeOpacity(colorScheme),
                        stroke: computeAxisLineColor(colorScheme),
                    },
                },
            },
            label: {
                formatter: (text: string) => {
                    return formatDate(text, 'DD.MM.YYYY');
                },
            },
        };

        // @ts-ignore
        config.area = {
            style: (d: {type: string}) => {
                const {type} = d;

                if (type.includes('Факт')) {
                    return {
                        fill: 'l(90) 1:#00968800 0.65:#00968875 0:#00968875',
                        shadowColor: '#00968875',
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowOffsetY: 5,
                    };
                }
                if (type.includes('План')) {
                    return {
                        fill: 'l(90) 0:#00968800 0.5:#00968800 1:#00968800',
                        fillOpasity: 0,
                    };
                }
            },
        };
    }

    if (code === ChartCode.GEOLOGICAL_JOURNAL) {
        //@ts-ignore
        config.interactions = [];
    }

    if (
        code === ChartCode.TRAJECTORY_HORIZONTAL_SLICE ||
        code === ChartCode.TRAJECTORY_VERTICAL_SLICE
    ) {
        //@ts-ignore
        config.xAxis = {
            grid: {
                line: {
                    style: {
                        stroke: computeAxisLineColor(colorScheme),
                        lineWidth: 1,
                    },
                },
                visible: true,
            },
        };
    }
    return config;
};

const getWaterfallConfig = (
    colorScheme: SupportedColorScheme | undefined,
    data: WaterfallChartProps,
): Omit<WaterfallConfig, 'data'> => {
    const annotations: Annotation[] = [];

    const total = data.reduce((acc, d) => {
        if (d.title === 'План') {
            return acc + d.value;
        }

        const percentDeviation = ((d.value / acc) * 100).toFixed(1);

        annotations.push({
            type: 'text',
            position: () => {
                const y = acc + d.value;
                return [d.title, y];
            },
            offsetY: d.value > 0 ? -12 : 12,
            content:
                Number(percentDeviation) > 0 ? `+${percentDeviation} %` : `${percentDeviation} %`,
            style: {
                fontSize: 10,
                fill: computeTextColor(colorScheme),
                lineWidth: 1,
                textAlign: 'center',
                verticalAlign: 'middle',
            },
        });

        return acc + d.value;
    }, 0);

    const dataWithTotal = [...data, {title: 'Факт', value: total}];

    const maxValue = Math.max(...dataWithTotal.map((d) => d.value));

    const config: Omit<WaterfallConfig, 'data'> = {
        padding: 'auto',
        appendPadding: [20, 0, 0, 0],
        xField: 'title',
        yField: 'value',

        total: {
            label: 'Факт',
        },

        xAxis: {
            label: {
                autoHide: false,
                autoRotate: false,

                formatter: (text: string) => {
                    return splitStringToLines(text, 10);
                },
            },
        },

        yAxis: {
            min: 0,
            max: maxValue * 1.1,
            grid: {
                line: {
                    style: {
                        lineWidth: 1,
                        strokeOpacity: computeStrokeOpacity(colorScheme),
                        stroke: computeAxisLineColor(colorScheme),
                        lineDash: null,
                    },
                },
            },
        },

        color: ({title, value}) => {
            if (title === 'План') {
                return colorScheme === 'light'
                    ? 'rgba(218, 218, 218)'
                    : 'rgba(255, 255, 255, 0.12)';
            }

            if (title === 'Факт') {
                return 'rgba(41, 99, 197, 1)';
            }

            return value < 0 ? '#4DB6AC' : '#E04134';
        },
        legend: false,

        label: {
            position: 'top',
            style: {
                fontSize: 10,
                fill: computeTextColor(colorScheme),
            },

            formatter: (item) => {
                return `${item.value.toFixed(2)}`;
            },

            layout: [
                {
                    type: 'interval-adjust-position',
                },
            ],
        },
        waterfallStyle: () => {
            return {
                fillOpacity: 0.85,
            };
        },

        tooltip: false,

        annotations: annotations,
    };

    return config;
};

const isPieChart = (chart: ChartProps): chart is PieChartItem[] => {
    return Array.isArray(chart) && chart.every((item) => 'name' in item && 'value' in item);
};

export const getChartData = (chart: ChartItem, props: ChartProps) => {
    if (chart.type === ChartType.Line) {
        const data: LineChartItem[] = [];

        (props as LineChartProps).forEach((line) => {
            const chartItems = getLineChartData(line?.values, line?.title);

            data.push(...chartItems);
        });

        return data;
    }

    if (
        chart.code === ChartCode.KNBK_PARAMETERS_REFERENCE ||
        chart.code === ChartCode.KNBK_SINKING_REFERENCE
    ) {
        return (props as ColumnChartProps).map((row) => {
            return {
                ...row,
                // Так как нам надо подстроить высоту названия оси под соседний график
                x: row.x.replace(/(Cкв\.)/, '\n$1'),
            };
        });
    }

    if (chart.type === ChartType.Column || chart.type === ChartType.Bar) {
        return (props as ColumnChartProps).map((row) => {
            return {
                ...row,
                x: splitStringToLines(row.x),
            };
        });
    }

    // Need to filter pie data in order to avoid label with 0
    if (chart.code === ChartCode.WELL_OPERATIONS_PV_NPV) {
        if (isPieChart(props)) {
            return props.filter((i) => i.value !== 0);
        }
    }

    return props;
};

export const getLineChartData = (data: LineChartItem[], title: string): LineChartItem[] => {
    return data.map((item) => ({...item, type: title, initialX: item.x, x: item.x}));
};

export const getChartSize = (chart: ChartItem) => {
    const customWidth: Record<string, number> = {
        // ChartCode.Параметры бурения: 12,
    };

    const customHeight: Record<string, string> = {
        [ChartCode.DRILLING_CHEMICAL_REAGENTS_CONSUMPTIONS]: '890px',
        [ChartCode.FASTENING_CHEMICAL_REAGENTS_CONSUMPTIONS]: '890px',
        [ChartCode.DEVELOPMENT_CHEMICAL_REAGENTS_CONSUMPTIONS]: '890px',
        [ChartCode.MTR_CONSUMPTIONS]: '890px',
    };
    return {
        width: customWidth[chart.code] || 6,
        height: customHeight[chart.code] || '420px',
    };
};

export const computeFill = (colorScheme: SupportedColorScheme | undefined): string => {
    if (colorScheme) {
        return colorScheme === 'dark' ? 'rgba(255, 255, 255, 0.70)' : 'rgba(0, 0, 0, 0.60)';
    }
    return 'rgba(0, 0, 0, 0.60)';
};

export const computeAxisLineColor = (colorScheme: SupportedColorScheme | undefined): string => {
    if (colorScheme === 'dark') return 'rgba(255, 255, 255, 0.70)';
    return 'rgba(0, 0, 0, 0.10)';
};

const computeStrokeColor = (colorScheme: SupportedColorScheme | undefined): string => {
    if (colorScheme === 'dark') return '#5E5E5E';
    return '#666666';
};

export const computeStrokeOpacity = (colorScheme: SupportedColorScheme | undefined): number => {
    if (colorScheme === 'dark') return 0.1;
    return 0.4;
};

export const computeTextColor = (colorScheme: SupportedColorScheme | undefined): string => {
    if (colorScheme === 'dark') return '#FFFFFF';
    return '#000000';
};

const combiningConfigs = (
    defConfig: Record<string, any>,
    config: Record<string, any>,
): Record<string, any> => {
    const result = {...defConfig};

    for (const key in config) {
        if (key in result) {
            if (typeof result[key] === 'object' && typeof config[key] === 'object') {
                result[key] = combiningConfigs(result[key], config[key]);
            } else {
                result[key] = config[key];
            }
        } else {
            result[key] = config[key];
        }
    }

    return result;
};
