import {LineOptions as G2plotConfig} from '@antv/g2plot';
import {SxProps} from '@mui/material';

export enum ChartType {
    Line = 'LINE',
    Bar = 'BAR_HORIZONTAL',
    Column = 'BAR_VERTICAL',
    Pie = 'PIE',
    RangeBar = 'RANGE_BAR',
    Waterfall = 'WATERFALL',
}

export enum ChartCode {
    GGD_FACT = 'GGD_FACT',
    GGD_FACT_BY_WELLS = 'GGD_FACT_BY_WELLS',
    WELL_OPERATIONS_PV_NPV = 'WELL_OPERATIONS_PV_NPV',
    DRILLING_CHEMICAL_REAGENTS_CONSUMPTIONS = 'DRILLING_CHEMICAL_REAGENTS_CONSUMPTIONS',
    OPERATION_WORK_CATEGORY = 'OPERATION_WORK_CATEGORY',
    TIME_NPV = 'TIME_NPV',
    BUILDING_STAGE = 'BUILDING_STAGE',
    WASTE_GENERATION_ACCOUNTING_PLAN_FACT = 'WASTE_GENERATION_ACCOUNTING_PLAN_FACT',
    MTR_CONSUMPTIONS = 'MTR_CONSUMPTIONS',
    BUILDING_DATES_BY_STAGE = 'BUILDING_DATES_BY_STAGE',
    DURATION_BY_WORK_CATEGORY = 'DURATION_BY_WORK_CATEGORY',
    DURATION_BY_WORK_TYPE = 'DURATION_BY_WORK_TYPE',
    DRILLING_PARAMETERS = 'DRILLING_PARAMETERS',
    COST_BY_DAY = 'COST_BY_DAY',
    TRAJECTORY_VERTICAL_SLICE = 'TRAJECTORY_VERTICAL_SLICE',
    TRAJECTORY_HORIZONTAL_SLICE = 'TRAJECTORY_HORIZONTAL_SLICE',
    ASSIMILATION_OPERATIONS_PV_NPV = 'ASSIMILATION_OPERATIONS_PV_NPV',
    FIELD_OPERATIONS_PV_NPV = 'FIELD_OPERATIONS_PV_NPV',
    COST_BY_SERVICE = 'COST_BY_SERVICE',
    PV_NPV_BY_WELL = 'PV_NPV_BY_WELL',
    NPV_BY_WELL = 'NPV_BY_WELL',
    TIME_DISTRIBUTION = 'TIME_DISTRIBUTION',
    DAYS_COUNT_BY_1000M = 'DAYS_COUNT_BY_1000M',
    PENETRATION_SPEED_INFO_BY_WELL = 'PENETRATION_SPEED_INFO_BY_WELL',
    STAGE_DURATION = 'STAGE_DURATION',
    FASTENING_CHEMICAL_REAGENTS_CONSUMPTIONS = 'FASTENING_CHEMICAL_REAGENTS_CONSUMPTIONS',
    DEVELOPMENT_CHEMICAL_REAGENTS_CONSUMPTIONS = 'DEVELOPMENT_CHEMICAL_REAGENTS_CONSUMPTIONS',
    KNBK_PARAMETERS = 'KNBK_PARAMETERS',
    KNBK_SINKING = 'KNBK_SINKING',
    KNBK_PARAMETERS_REFERENCE = 'KNBK_PARAMETERS_REFERENCE',
    KNBK_SINKING_REFERENCE = 'KNBK_SINKING_REFERENCE',
    OT_PB_OOS_BY_WELL = 'OT_PB_OOS_BY_WELL',
    OT_PB_OOS_BY_WELLS = 'OT_PB_OOS_BY_WELLS',
    GEOLOGICAL_JOURNAL = 'GEOLOGICAL_JOURNAL',
    OT_PB_OOS_BY_WELL_AND_STAGE = 'OT_PB_OOS_BY_WELL_AND_STAGE',
    OT_PB_OOS_BY_WELLS_AND_STAGE = 'OT_PB_OOS_BY_WELLS_AND_STAGE',
    COST_EXPENSE_STRUCTURE_BY_CASING_COLUMN = 'COST_EXPENSE_STRUCTURE_BY_CASING_COLUMN',
    COST_DEVIATION = 'COST_DEVIATION',
    NPV_ANALYTICS = 'NPV_ANALYTICS',
}

export type ChartParams = {
    code: string;
    title: string;
    possibleValues: string[];
    defaultValues: string[];
    isSingleChoice: boolean;
};

export type LineChartItem = {x: number; y: number};

export type ChartLine = {
    values: LineChartItem[];
    title: string;
};

export type LineChartProps = ChartLine[];

export type PieChartItem = {name: string; value: number};

export type PieChartProps = PieChartItem[];

export type BarChartItem = {x: string; y: number; valueType: string; groupField?: string};

export type BarChartProps = BarChartItem[];
export type ColumnChartProps = BarChartItem[];

export type WaterfallChartItem = {title: string; value: number};
export type WaterfallChartProps = WaterfallChartItem[];

export type RangeBarItem = {
    x: [string, string];
    y: string;
    seriesName: string;
    groupName: string;
};

export type RangeBarProps = RangeBarItem[];

export type ChartProps = any;

export type ChartItem = {
    title: string;
    type: ChartType;
    code: ChartCode;
    params: ChartParams[];
    settings?: Record<string, boolean> & {reflect?: ReflectConfig};
};

export type ChartFilter = Record<string, boolean | string[] | string | number | number[]>;

export type ChartComponentProps = {
    chart: ChartItem;
    className?: string;
    wellId?: string | string[];
    withFilters?: boolean;
    customChartProps?: Record<string, any>;
    customConfig?: (
        config: Omit<G2plotConfig, 'tooltip' | 'data'>,
        values?: ChartProps,
    ) => G2plotConfig;
    hideTitle?: boolean;
    forceReload?: number;
    outerFilters?: ChartFilter;
    fullscreen?: {
        onClick: () => void;
        isFullscreen: boolean;
        buttonStyles?: SxProps;
    };
    outerKey?: number;
    setData?: (data: ChartProps) => void;
    titleFontSize?: string;
};

export type ChartFiltersProps = {
    chart: ChartItem;
    setFilters: React.Dispatch<React.SetStateAction<ChartFilter>>;
    filters: ChartFilter;
};

export type ChartFilterProps = {
    filter: ChartParams;
    onFilterChange: (filterCode: string, newFilterValue: string[]) => void;
};

export type ChartAutocompleteProps = {
    filter: ChartParams;
    open: boolean;
    onOpen: () => void;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    value: string[];
    setValue: React.Dispatch<React.SetStateAction<string[]>>;
    isSingleChoice: boolean;
};

export enum ReflectConfig {
    X = 'X',
    Y = 'Y',
    XY = 'XY',
}
