import {DesktopDateTimePicker} from '@mui/x-date-pickers';
import {
    Autocomplete,
    AutocompleteRenderOptionState,
    Checkbox,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import {Incident, NpvFileType} from '../types';
import React, {useRef, useState} from 'react';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {useDictionary} from 'utils/hooks/useDictionary';
import {useSelector} from 'react-redux';
import {selectDictionaries} from 'slices';
import {FileWithProgressAndType, NpvFilesEdit} from '../NpvFilesEdit';
import {useParams} from 'react-router-dom';
import {DictionaryItem, DT_FORMATS, formatDate, invokeAsync} from 'utils';
import dayjs from 'dayjs';
import {IncidentService} from '@services/IncidentService/IncidentService';
import {getIncidentTypeByFullValue, getIncidentValue, isIncidentType} from '../utils';
import InputLabel from '@mui/material/InputLabel';
import {renderTags} from 'utils/functions/renderTags';
import {useKeepOpen} from 'utils/hooks/useKeepOpen';

const generateTitle = (incident: Incident) => {
    return `${incident.workType}`;
};

type IncidentDetailsEditProps = {
    data: Incident;
    onSave: (id?: number) => void;
    refetchAll?: () => void;
    disableConfig?: Record<string, boolean>;
};
export const IncidentDetailsEdit: React.FC<IncidentDetailsEditProps> = ({
    data,
    onSave,
    refetchAll,
    disableConfig = {},
}) => {
    const [titleChangedByUser, setTitleChangedByUser] = useState<boolean>(
        !!data.title && data.title !== generateTitle(data),
    );

    const {handleSubmit, register, control, watch, getValues, setValue} = useForm<Incident>({
        values: {
            ...data,
            title: data.title ? data.title : generateTitle(data),
        },
    });

    const params = useParams();
    const casingColumnApi = 'CasingColumnName';
    const constructionStageApi = 'WellConstructionStage';
    const workTypeApi = 'WorkType';
    const wellboreApi = 'Wellbore';
    const serviceTypeApi = 'ServiceType';

    useDictionary({code: casingColumnApi});
    useDictionary({code: workTypeApi, key: 'Непроизводительное время'});
    useDictionary({code: constructionStageApi});
    useDictionary({code: wellboreApi});
    useDictionary({code: serviceTypeApi});
    const dictionaries = useSelector(selectDictionaries);

    const [isWithoutAct, setIsWithoutAct] = useState<boolean>(data.isWithoutAct || false);
    const [comment, setComment] = useState<string>(data.comment || '');
    const onSubmit: SubmitHandler<Incident> = (data: Incident) => {
        const method = data.id ? IncidentService.editData : IncidentService.createData;
        invokeAsync(() => {
            const photos = [
                ...(data.photos || [])
                    .filter((file) => file.type === NpvFileType.PHOTO)
                    .filter((file) => !filesToDelete.current.find((fileId) => file.id === fileId))
                    .map((file) => ({
                        id: file.id,
                        type: file.type,
                    })),
                ...filesToAdd.current
                    .filter((file) => file.npvType === NpvFileType.PHOTO)
                    .map((file) => ({
                        id: file.id,
                        type: file.npvType,
                    })),
            ];
            const files = [
                ...(data.files || [])
                    .filter((file) => !filesToDelete.current.find((fileId) => file.id === fileId))
                    .filter((file) => file.type !== NpvFileType.PHOTO)
                    .map((file) => ({
                        id: file.id,
                        type: file.type,
                    })),
                ...filesToAdd.current
                    .filter((file) => file.npvType !== NpvFileType.PHOTO)
                    .map((file) => ({
                        id: file.id,
                        type: file.npvType,
                    })),
            ];
            return method(
                {
                    ...data,
                    startTime: formatDate(data.startTime, DT_FORMATS.utcFull),
                    endTime: formatDate(data.endTime, DT_FORMATS.utcFull),
                    type: getIncidentTypeByFullValue(data.type),
                    serviceTypeCodes: (data?.serviceTypes || []).map(({code}) => code),
                    isWithoutAct,
                    comment,
                    // Бэк ждёт актуальный список файлов при сохранении всего Инцидента
                    // Соответственно, мы должны убрать удалённые файлы и добавить новые.
                    files: files.length === 0 ? null : files,
                    photos: photos.length === 0 ? null : photos,
                },
                {
                    wellId: params.wellId,
                    id: data.id,
                },
            ).then((data) => {
                refetchAll?.();
                onSave(Number(data.id));
                filesToDelete.current = [];
                filesToAdd.current = [];
            });
        });
    };

    const watchStartTime = watch('startTime');

    const filesToAdd = useRef<FileWithProgressAndType[]>([]);
    const filesToDelete = useRef<string[]>([]);
    const casingColumnNamesDictionary = dictionaries[casingColumnApi] || [];
    const npvWorkTypeNamesDictionary = dictionaries[workTypeApi] || [];
    const wellBoreDictionary = dictionaries[wellboreApi] || [];
    const serviceTypeDictionary = dictionaries[serviceTypeApi] || [];
    const casingColumnNames = casingColumnNamesDictionary.map((el) => el.value);
    const workTypeNames = npvWorkTypeNamesDictionary.map((el) => el.value);
    const constructionStageNamesDictionary = dictionaries[constructionStageApi] || [];
    const constructionStageNames = constructionStageNamesDictionary.map((el) => el.value);
    const wellBoreNames = wellBoreDictionary.map((el) => el.value);
    const serviceTypesOptions = serviceTypeDictionary.map((el) => el);

    const renderServiceTypeOption = (
        props: React.HTMLAttributes<HTMLLIElement>,
        option: DictionaryItem,
        state: AutocompleteRenderOptionState,
    ) => {
        return (
            <li
                {...props}
                style={{padding: 0}}
            >
                <Checkbox checked={state.selected} />
                {option.title}
            </li>
        );
    };

    return (
        <>
            <form
                onSubmit={handleSubmit(onSubmit)}
                id='editIncidentForm'
            >
                <Stack
                    direction='column'
                    gap='16px'
                >
                    <Stack
                        direction='row'
                        justifyContent='space-between'
                        gap='16px'
                    >
                        <Controller
                            control={control}
                            name='type'
                            rules={{
                                required: true,
                            }}
                            defaultValue={data.type}
                            render={({field}) => {
                                return (
                                    <Autocomplete
                                        {...field}
                                        sx={{width: '115px', flexShrink: 0}}
                                        disableClearable
                                        disabled
                                        options={[]}
                                        onChange={(_, values) => {
                                            field.onChange(values);
                                        }}
                                        ref={register('type').ref}
                                        getOptionLabel={(option) => {
                                            if (isIncidentType(option)) {
                                                return getIncidentValue(option);
                                            }
                                            return option;
                                        }}
                                        renderInput={(params) => {
                                            return (
                                                <TextField
                                                    {...params}
                                                    label='Вид инцидента'
                                                    variant='standard'
                                                />
                                            );
                                        }}
                                    />
                                );
                            }}
                        />
                        <Stack
                            direction='row'
                            gap='4px'
                            alignItems='self-end'
                            sx={{flexGrow: 1, flexShrink: 0, position: 'relative'}}
                        >
                            <InputLabel
                                sx={{transform: 'scale(0.75)', position: 'absolute', top: 0}}
                            >
                                Название
                            </InputLabel>
                            <Typography
                                color='text.secondary'
                                component='div'
                                flexShrink={0}
                                mb='4px'
                            >
                                {formatDate(watchStartTime, DT_FORMATS.default)}
                            </Typography>
                            <Controller
                                control={control}
                                name='title'
                                rules={{
                                    required: true,
                                }}
                                render={({field}) => {
                                    return (
                                        <TextField
                                            {...field}
                                            onChange={(event) => {
                                                field.onChange(event);
                                                const formValues = getValues();
                                                setTitleChangedByUser(
                                                    event.target.value !==
                                                        generateTitle(formValues),
                                                );
                                            }}
                                            ref={register('title').ref}
                                            id='title'
                                            size='small'
                                            variant='standard'
                                            style={{flexGrow: 1}}
                                        />
                                    );
                                }}
                            />
                        </Stack>

                        <Controller
                            control={control}
                            name='startTime'
                            rules={{
                                required: true,
                            }}
                            render={({field}) => {
                                return (
                                    <DesktopDateTimePicker
                                        {...field}
                                        onChange={(value) => {
                                            field.onChange(value);
                                        }}
                                        slotProps={{
                                            textField: {
                                                label: 'Дата начала',
                                                variant: 'standard',
                                            },
                                        }}
                                        disabled={disableConfig?.startTime}
                                        ref={register('startTime').ref}
                                        ampm={false}
                                        format={DT_FORMATS.default}
                                        sx={{
                                            width: '160px',
                                        }}
                                        value={field.value ? dayjs(field.value) : undefined}
                                    />
                                );
                            }}
                        />
                        <Controller
                            control={control}
                            name='endTime'
                            render={({field}) => {
                                return (
                                    <DesktopDateTimePicker
                                        {...field}
                                        slotProps={{
                                            textField: {
                                                label: 'Дата окончания',
                                                variant: 'standard',
                                            },
                                        }}
                                        ref={register('endTime').ref}
                                        ampm={false}
                                        format={DT_FORMATS.default}
                                        sx={{
                                            width: '160px',
                                        }}
                                        value={field.value ? dayjs(field.value) : undefined}
                                    />
                                );
                            }}
                        />
                    </Stack>
                    <Stack
                        direction='row'
                        justifyContent='space-between'
                        alignItems='flex-end'
                        gap='16px'
                    >
                        <Controller
                            control={control}
                            name='wellConstructionStage'
                            rules={{
                                required: true,
                            }}
                            defaultValue={data.wellConstructionStage}
                            render={({field}) => {
                                return (
                                    <Autocomplete
                                        {...field}
                                        sx={{width: '158px', flexShrink: 0}}
                                        disableClearable
                                        disabled={disableConfig?.wellConstructionStage}
                                        options={constructionStageNames}
                                        onChange={(event, values) => {
                                            field.onChange(values);
                                        }}
                                        ref={register('wellConstructionStage').ref}
                                        renderInput={(params) => {
                                            return (
                                                <TextField
                                                    {...params}
                                                    label='Этап строительства'
                                                    variant='standard'
                                                />
                                            );
                                        }}
                                    />
                                );
                            }}
                        />
                        <Controller
                            control={control}
                            name='wellbore'
                            defaultValue={data.wellbore}
                            render={({field}) => {
                                return (
                                    <Autocomplete
                                        {...field}
                                        sx={{width: '250px', flexShrink: 0}}
                                        disableClearable
                                        options={wellBoreNames}
                                        onChange={(event, values) => {
                                            field.onChange(values);
                                        }}
                                        ref={register('wellbore').ref}
                                        renderInput={(params) => {
                                            return (
                                                <TextField
                                                    {...params}
                                                    label='Ствол'
                                                    variant='standard'
                                                />
                                            );
                                        }}
                                    />
                                );
                            }}
                        />
                        <Controller
                            control={control}
                            name='casingColumn'
                            rules={{
                                required: false,
                            }}
                            defaultValue={data.casingColumn}
                            render={({field}) => {
                                return (
                                    <Autocomplete
                                        {...field}
                                        sx={{width: '100%'}}
                                        disableClearable
                                        disabled={disableConfig?.casingColumn}
                                        options={casingColumnNames}
                                        ref={register('casingColumn').ref}
                                        onChange={(event, values) => {
                                            field.onChange(values);
                                        }}
                                        renderInput={(params) => {
                                            return (
                                                <TextField
                                                    {...params}
                                                    label='Обсадная колонна'
                                                    variant='standard'
                                                />
                                            );
                                        }}
                                    />
                                );
                            }}
                        />
                    </Stack>
                    <Stack
                        direction='row'
                        justifyContent='space-between'
                        alignItems='flex-end'
                        gap='16px'
                    >
                        <Controller
                            control={control}
                            name='workType'
                            rules={{
                                required: true,
                            }}
                            defaultValue={data.workType}
                            render={({field}) => {
                                return (
                                    <Autocomplete
                                        {...field}
                                        sx={{width: '440px'}}
                                        disableClearable
                                        disabled={disableConfig?.workType}
                                        options={workTypeNames}
                                        onChange={(_, value) => {
                                            if (titleChangedByUser) {
                                                setTitleChangedByUser(
                                                    getValues().title !==
                                                        generateTitle({
                                                            ...getValues(),
                                                            workType: value,
                                                        }),
                                                );
                                            } else {
                                                setValue(
                                                    'title',
                                                    generateTitle({
                                                        ...getValues(),
                                                        workType: value,
                                                    }),
                                                );
                                            }
                                            field.onChange(value);
                                        }}
                                        ref={register('workType').ref}
                                        renderInput={(params) => {
                                            return (
                                                <TextField
                                                    {...params}
                                                    label='Вид работ'
                                                    variant='standard'
                                                />
                                            );
                                        }}
                                    />
                                );
                            }}
                        />
                        <TextField
                            id='run'
                            size='small'
                            label='№ Рейса'
                            {...register('run', {
                                required: false,
                            })}
                            type='number'
                            sx={{width: '120px'}}
                            variant='standard'
                            disabled={disableConfig?.run}
                        />
                        <TextField
                            id='startDepth'
                            size='small'
                            label='Интервал От, м'
                            {...register('startDepth', {
                                required: false,
                            })}
                            type='number'
                            sx={{width: '120px'}}
                            variant='standard'
                            disabled={disableConfig?.startDepth}
                            inputProps={{min: 0, step: 0.01}}
                        />
                        <TextField
                            id='endDepth'
                            size='small'
                            label='Интервал До, м'
                            {...register('endDepth', {
                                required: false,
                            })}
                            type='number'
                            sx={{width: '120px'}}
                            variant='standard'
                            disabled={disableConfig?.endDepth}
                            inputProps={{min: 0, step: 0.01}}
                        />
                    </Stack>
                    <TextField
                        id='description'
                        size='small'
                        multiline
                        label='Описание и причины возникновения'
                        {...register('description', {
                            required: false,
                        })}
                        sx={{width: '100%'}}
                        variant='standard'
                    />
                    <TextField
                        id='takenAction'
                        size='small'
                        multiline
                        label='Принятые меры по ликвидации'
                        {...register('takenAction', {
                            required: false,
                        })}
                        sx={{width: '100%'}}
                        variant='standard'
                    />
                    <Stack
                        direction='row'
                        justifyContent='space-between'
                        sx={{flexWrap: 'wrap'}}
                        alignItems='center'
                    >
                        <Typography
                            variant='subtitle1'
                            sx={{width: '100%'}}
                        >
                            Виновная сторона
                        </Typography>
                        <Controller
                            control={control}
                            name='serviceTypes'
                            defaultValue={data.serviceTypes}
                            render={({field}) => {
                                return (
                                    <Autocomplete
                                        {...field}
                                        // eslint-disable-next-line react-hooks/rules-of-hooks
                                        {...useKeepOpen()}
                                        sx={{width: '240px', mr: 4}}
                                        options={serviceTypesOptions}
                                        multiple
                                        onChange={(event, values) => {
                                            field.onChange(values);
                                        }}
                                        renderOption={renderServiceTypeOption}
                                        getOptionLabel={(option) => option.title}
                                        renderTags={renderTags}
                                        isOptionEqualToValue={(option, value) =>
                                            option.code === value.code
                                        }
                                        renderInput={(params) => {
                                            return (
                                                <TextField
                                                    {...params}
                                                    label='Тип сервиса'
                                                    variant='standard'
                                                />
                                            );
                                        }}
                                    />
                                );
                            }}
                        />
                        <TextField
                            id='guiltySide'
                            size='small'
                            multiline
                            label='Виновная сторона'
                            {...register('guiltySide', {
                                required: false,
                            })}
                            sx={{flexGrow: 1}}
                            variant='standard'
                        />
                    </Stack>
                </Stack>
                <div style={{margin: '16px 0 8px'}}>
                    <Typography variant='subtitle1'>Документы</Typography>
                </div>
                <NpvFilesEdit
                    data={data}
                    filesToAdd={filesToAdd}
                    filesToDelete={filesToDelete}
                    comment={comment}
                    setComment={setComment}
                    setWithoutAct={setIsWithoutAct}
                    isWithoutAct={isWithoutAct}
                />
            </form>
        </>
    );
};
