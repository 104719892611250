import {AccountData, AccountDataWithPassword} from '@features/accounts/types';
import {PaginatedResponse} from 'types/pagination';
import {PageSchema, ParamsType} from 'utils';
import api from 'utils/api';

const ROOT_URL = '/admin/users';

const getData = (params: ParamsType) => {
    return api.get<PaginatedResponse<AccountData[]>>(`${ROOT_URL}/table-view`, params);
};

const getAccountsTableSchema = () => {
    return api.get<PageSchema>(`${ROOT_URL}/table-view/schema`);
};

const createData = (data: AccountDataWithPassword) => {
    return api.post<AccountData>(`${ROOT_URL}`, data);
};

const getUserData = (id: string) => {
    return api.get<AccountData>(`${ROOT_URL}/${id}`);
};

const updateData = (id: string, data: AccountDataWithPassword) => {
    return api.put<AccountData>(`${ROOT_URL}/${id}`, data);
};

const deleteData = (ids: number[]): Promise<void> => {
    return api.delete(`${ROOT_URL}`, ids);
};

const blockUsers = (ids: number[]) => {
    return api.post(`${ROOT_URL}/block`, ids);
};

const unblockUsers = (ids: number[]) => {
    return api.post(`${ROOT_URL}/unblock`, ids);
};

export const AccountsService = {
    getData,
    getAccountsTableSchema,
    createData,
    updateData,
    deleteData,
    getUserData,
    blockUsers,
    unblockUsers,
};
