import {Dialog, DialogContent, DialogTitle} from '@mui/material';
import {AccountDataWithPassword, OpenModal} from '../types';
import {AccountModalForm} from './AccountModalForm';
import {useQuery} from '@tanstack/react-query';
import {AccountsService} from '@services/Accounts/AccountsService';
import {useSnackbar} from 'notistack';
import {SuccessNotification} from '../AccontNotifications/SuccessNotification';

type AccountModalProps = {
    id?: string;
    setOpenModal: React.Dispatch<React.SetStateAction<OpenModal>>;
    setForceRefetch: React.Dispatch<React.SetStateAction<number>>;
};

export const AccountModal = ({id, setOpenModal, setForceRefetch}: AccountModalProps) => {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const handleClose = (withRefetch?: boolean) => {
        setOpenModal(null);
        if (withRefetch) {
            setForceRefetch((prev) => prev + 1);
        }
    };

    const title = id ? 'Редактирование учетной записи' : 'Создание учетной записи';

    const {data: accountData} = useQuery({
        queryKey: [`getUserData_${id}`, id],
        queryFn: () => AccountsService.getUserData(id || ''),
        gcTime: 0,
        enabled: !!id,
    });

    const setOpenNotification = (data: AccountDataWithPassword) => {
        enqueueSnackbar('Успешно', {
            persist: false,
            autoHideDuration: 5000,
            content: (key) => (
                <SuccessNotification
                    onClose={() => closeSnackbar(key)}
                    data={data}
                />
            ),
        });
    };

    return (
        <Dialog open>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent sx={{width: '480px'}}>
                <AccountModalForm
                    handleClose={handleClose}
                    initialData={accountData}
                    setOpenNotification={setOpenNotification}
                />
            </DialogContent>
        </Dialog>
    );
};
