import {AccountsService} from '@services/Accounts/AccountsService';
import NewTable from '@ui/NewTable/NewTable';
import {useEffect} from 'react';
import {useTablePage} from 'utils/hooks/useTablePage';
import {AccountData, OpenModal, OperationStatus, UserStatus} from './types';
import {useAccountsTableData} from './useAccountsTableData';
import {formateAccountsColumns} from './utils';
import {setNotification} from 'slices/notifications/notificationsSlice';
import {useAppDispatch} from 'slices/hooks';
import {Row} from '@ui/NewTable/types';
import {SettingsMenuItem} from '@ui/SettingsMenu/types';
import {BlockOutlined} from '@mui/icons-material';
import {useMutation} from '@tanstack/react-query';

type AccountsTableProps = {
    setOpenModal: React.Dispatch<React.SetStateAction<OpenModal>>;
    forceRefetch: number;
};

export const AccountsTable = ({setOpenModal, forceRefetch}: AccountsTableProps) => {
    const service = AccountsService;
    const [pageStore, updatePageData] = useAccountsTableData();
    const dispatch = useAppDispatch();

    const schemaGetter = () => service.getAccountsTableSchema();

    const {
        page,
        rowsPerPage,
        sortConfig,
        schema,
        columns,
        tableConfig,
        requiredConfig,
        rows,
        ref,
        handleUpdate,
        setPage,
        setRowsPerPage,
        setSortConfig,
        setUpdateDictionaries,
        filterConfig,
        setFilterConfig,
    } = useTablePage({
        pageId: 'accounts',
        pageStore,
        service,
        updatePageData,
        schemaGetter,
    });

    const customAddClick = () => {
        setOpenModal({open: true});
    };

    const customEditClick = (data: AccountData) => {
        setOpenModal({open: true, id: data.id});
    };

    const deleteEntityHandle = (ids: number[]) => {
        return service.deleteData(ids);
    };

    useEffect(() => {
        if (!!schema) {
            handleUpdate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, schema, sortConfig, rowsPerPage, filterConfig, forceRefetch]);

    const formattedColumns = formateAccountsColumns(columns);

    useEffect(() => {
        if (rows) {
            if (rows.some((row) => row.operationStatus === OperationStatus.PROCESSING)) {
                dispatch(
                    setNotification({
                        title: 'Запрос обрабатывается. Обновите страницу, чтобы узнать статус запроса.',
                        text: '',
                        variant: 'warning',
                    }),
                );
            }
            if (rows.some((row) => row.operationStatus === OperationStatus.ERROR)) {
                dispatch(
                    setNotification({
                        title: 'При обработке запроса произошла ошибка. Попробуйте еще раз или обратитесь в тех.поддержку. ',
                        text: '',
                        variant: 'error',
                    }),
                );
            }
        }
    }, [dispatch, rows]);

    const mutateBlockUsers = useMutation({
        mutationKey: ['blockUsers'],
        mutationFn: (ids: number[]) => service.blockUsers(ids),
        onSuccess: () => {
            handleUpdate();
        },
    });

    const mutateUnblockUsers = useMutation({
        mutationKey: ['unblockUsers'],
        mutationFn: (ids: number[]) => service.unblockUsers(ids),
        onSuccess: () => {
            handleUpdate();
        },
    });

    const renderAdditionalRowActions = ({row}: {row: Row}): SettingsMenuItem[] => {
        if (row.status === UserStatus.ACTIVE) {
            return [
                {
                    trigger: 'Заблокировать',
                    handler: () => {
                        mutateBlockUsers.mutate([row.id]);
                    },
                    icon: <BlockOutlined color='action' />,
                },
            ];
        }
        if (row.status === UserStatus.BLOCKED) {
            return [
                {
                    trigger: 'Разблокировать',
                    handler: () => {
                        mutateUnblockUsers.mutate([row.id]);
                    },
                    icon: <BlockOutlined color='action' />,
                },
            ];
        }
        return [];
    };

    const renderAdditionalHeaderActions = ({
        selected,
    }: {
        selected: number[];
    }): SettingsMenuItem[] => {
        return [
            {
                trigger: 'Разблокировать',
                disabled: selected.length === 0,
                handler: () => {
                    mutateUnblockUsers.mutate(selected);
                },
                icon: <BlockOutlined color='action' />,
            },
            {
                trigger: 'Заблокировать',
                disabled: selected.length === 0,
                handler: () => {
                    mutateBlockUsers.mutate(selected);
                },
                icon: <BlockOutlined color='action' />,
            },
        ];
    };

    if (!schema?.properties) {
        return null;
    }
    return (
        <div className='page'>
            <div
                ref={ref}
                className='table-container'
            >
                <NewTable
                    tableName='accounts'
                    columns={formattedColumns}
                    tableConfig={tableConfig}
                    rows={rows}
                    filterConfig={filterConfig}
                    setFilterConfig={setFilterConfig}
                    customAddClick={customAddClick}
                    customEditClick={customEditClick}
                    deleteEntityHandle={deleteEntityHandle}
                    onUpdate={handleUpdate}
                    sortConfig={sortConfig}
                    setSortConfig={setSortConfig}
                    totalElements={pageStore?.total || 0}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                    requiredConfig={requiredConfig}
                    setUpdateDictionaries={setUpdateDictionaries}
                    renderAdditionalRowActions={renderAdditionalRowActions}
                    renderAdditionalHeaderActions={renderAdditionalHeaderActions}
                />
            </div>
        </div>
    );
};
