import {Autocomplete, AutocompleteRenderOptionState, Checkbox, TextField} from '@mui/material';
import {useCallback, useEffect, useRef, useState} from 'react';
import {ColumnData} from '../types';
import {renderTags} from 'utils/functions/renderTags';
import {useDict} from 'utils/hooks/useDict';
import {isEqual} from 'lodash';

type HeaderFilterProps = {
    column: ColumnData;
    handleChangeFilterValue: (value: string[]) => void;
    selectedOptions: string[];
    setShowFilter: React.Dispatch<React.SetStateAction<boolean>>;
};

export const HeaderFilter = ({
    column,
    handleChangeFilterValue,
    selectedOptions,
    setShowFilter,
}: HeaderFilterProps) => {
    const [value, setValue] = useState<string[]>(selectedOptions);
    const componentRef = useRef<HTMLDivElement>(null);

    const changeValue = useCallback(
        (newValue: string[]) => {
            if (isEqual(newValue, selectedOptions)) {
                // Если значение не изменилось, то закрываем фильтр, но не инициируем запрос
                setShowFilter(false);
                return;
            }
            handleChangeFilterValue(newValue);
        },
        [selectedOptions, setShowFilter, handleChangeFilterValue],
    );

    useEffect(() => {
        // Функция для обработки кликов, так как автокомплит по умолчанию должен быть открыт, и закрываться при клике вне его
        // А при изначально open = true функция закрытия не отрабатывает корректно (из-за логики внутри самого Autocomplete)
        function handleClickOutside(event: any) {
            // НЕ закрывает фильтр при кликах на инпут или элементы списка выбора, а также на иконку CloseIcon (очистка инпута)
            if (
                event.target.tagName.toLowerCase() === 'input' ||
                event.target.tagName.toLowerCase() === 'li'
            ) {
                return;
            }
            const isSvg = event.target.tagName === 'svg' || event.target.tagName === 'path';
            if (isSvg) {
                const svgParentId = event.target.closest('svg').getAttribute('data-testid');
                if (!!svgParentId && svgParentId === 'CloseIcon') {
                    return;
                }
            }
            // Закрываем фильтр при клике вне контейнера фильтра
            changeValue(value);
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const {data} = useDict({code: column.filterDictName});
    const options = (data || []).map((el) => el.value);

    const onClose = (_event: React.SyntheticEvent, reason: string) => {
        if (reason === 'blur' || reason === 'escape') {
            changeValue(value);
        }
    };

    const renderOption = (
        props: React.HTMLAttributes<HTMLLIElement>,
        option: string,
        state: AutocompleteRenderOptionState,
    ) => (
        <li
            {...props}
            style={{padding: '4px', hyphens: 'auto', lineHeight: '115%', wordBreak: 'break-word'}}
        >
            <Checkbox checked={state.selected} />
            {column.getFilterLabel ? column.getFilterLabel(option) : option}
        </li>
    );

    useEffect(() => {
        setValue(selectedOptions);
    }, [selectedOptions]);

    return (
        <div ref={componentRef}>
            <Autocomplete
                fullWidth
                open
                clearText='Очистить'
                sx={{minWidth: column?.width ? column?.width - 12 : 155}}
                onClose={onClose}
                renderOption={renderOption}
                renderTags={renderTags}
                options={options}
                value={value}
                multiple
                disableCloseOnSelect
                onChange={(_event: any, newValue: string[]) => {
                    setValue(newValue);
                }}
                getOptionLabel={(option) =>
                    column?.getFilterLabel ? column.getFilterLabel(option) : option
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant='standard'
                        label={column?.title}
                        size='small'
                        InputLabelProps={{
                            sx: {
                                paddingRight: '30px',
                            },
                        }}
                    />
                )}
            />
        </div>
    );
};
