import {TableCell as TableCellMui, TableCellProps} from '@mui/material';
import {formatValueToDisplay} from 'components/NewTable/utils';
import {PropsWithChildren} from 'react';
import styles from './NewTable.module.scss';
import {ColumnData, Row} from './types';
import {LightTooltip} from '@ui/LightTooltip/LightTooltip';
import classNames from 'classnames';

type NewTableCellProps = PropsWithChildren & {
    column: ColumnData;
    row: Row;
    customPadding?: string;
} & TableCellProps;

export const NewTableCell = ({
    column,
    row,
    children,
    customPadding,
    ...rest
}: NewTableCellProps) => {
    const value = row[column.id];
    return (
        <TableCellMui
            key={`${column.id}-${row?.id}`}
            className={classNames(styles.tableCell, {
                [styles.clickableCell]: !!column.properties?.onClick,
            })}
            style={{
                maxWidth: column?.width,
                padding: customPadding || '4px 8px',
            }}
            onClick={column.properties?.onClick?.(value)}
            {...rest}
        >
            {column.properties?.tooltip ? (
                <LightTooltip
                    title={column.properties.tooltip}
                    offset={[20, 0]}
                >
                    <div>
                        {formatValueToDisplay({
                            type: column.type,
                            format: column?.format,
                            value: value?.name ?? value,
                            columnWidth: column?.width,
                            actionType: column?.actionType,
                        })}
                    </div>
                </LightTooltip>
            ) : (
                children ||
                formatValueToDisplay({
                    type: column.type,
                    format: column?.format,
                    value: value?.name ?? value,
                    columnWidth: column?.width,
                    actionType: column?.actionType,
                    customDisplayFormat: column?.customDisplayFormat,
                    rowId: row?.id,
                })
            )}
        </TableCellMui>
    );
};
