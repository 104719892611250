import {useEffect, useState} from 'react';

import {ParamsType, RouteParams, invokeAsync} from 'utils';
import {TableSchemaService} from '../../services/Table';
import {useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {setDictionary} from '../../slices/dictionariesSlice';
import {ColumnData, TableConfig} from 'components/NewTable/types';
import {DrillingPlanService} from '@services/DrillingPlan/DrillingPlanService';

export type PageSchema<T extends string = string> = {
    properties?: Record<T, ColumnData>;
    config?: TableConfig;
    title?: string;
    description?: string;
    required?: string[];
};

const service = TableSchemaService.getInstance();

type Props = {
    id?: string;
    schemaGetter?: () => Promise<PageSchema>;
    initialSchema?: PageSchema;
    updateDictionaries?: number;
};

export const getDictionary = async ({code, params}: {code: string; params: ParamsType}) => {
    if (code === 'project') {
        const data = await service.getProjectDictionary();
        return data;
    }
    if (code === 'contract') {
        const data = await service.getContractDictionary();
        return data;
    }
    if (code === 'guiltySide') {
        const data = await service.getGuiltySideDictionary();
        return data;
    }
    if (code === 'drillingRig') {
        const data = await service.getDrillingRigDictionary();
        return data;
    }
    if (code === 'drillingTemplate') {
        const data = await DrillingPlanService.getDrillingTemplates(params);
        return data;
    }
    if (code === 'wellCompletionTemplate') {
        const data = await DrillingPlanService.getWellCompletionTemplates(params);
        return data;
    }
    if (code === 'padTemplate') {
        const data = await DrillingPlanService.getPadsTemplates();
        return data;
    }
    const data = await service.getDictionary({code, params});
    return data;
};

export const createDictionaryParams = (params: Partial<RouteParams>) => {
    const result: ParamsType = {};
    if (params.wellId) {
        result.wellId = params.wellId;
    }
    if (params.projectId) {
        result.projectId = params.projectId;
    }
    return result;
};

export const usePageSchema = ({id, schemaGetter, initialSchema, updateDictionaries}: Props) => {
    const dispatch = useDispatch();
    const params = useParams<RouteParams>();
    const [schema, setSchema] = useState<PageSchema | undefined>(initialSchema || undefined);
    const pageId = id ?? params.pageId;

    useEffect(() => {
        if (!initialSchema) {
            invokeAsync(async () => {
                setSchema(undefined);
                const request = schemaGetter
                    ? schemaGetter()
                    : pageId
                      ? service.getPageSchema(pageId)
                      : service.getGeneralSchema();

                setSchema((await request) as never);
            });
        }
        // eslint-disable-next-line
    }, [pageId, initialSchema]);

    useEffect(() => {
        if (schema) {
            invokeAsync(
                // eslint-disable-next-line require-await
                async () => {
                    Object.values(schema?.properties || {}).forEach(async (field, index) => {
                        if (field?.dictName && !field.dictKeyProperty) {
                            // Находим элемент, который в этой же схеме имеет такой же ключ, но при этом не имеет dictKeyProperty
                            const indexOfTheSameDictEl = Object.values(
                                schema?.properties || {},
                            ).findIndex(
                                (el) =>
                                    el.title !== field.title &&
                                    el.dictName === field.dictName &&
                                    !el?.dictKeyProperty,
                            );
                            const isTheSameElExist = indexOfTheSameDictEl !== -1;
                            // Если поля с таким же ключом НЕ сущестует больше в схеме
                            // ИЛИ существует, но он находиться дальше в схеме (то есть наш текущий filed первый с таким ключом)
                            // то делаем запрос за справочником
                            if (
                                !isTheSameElExist ||
                                (isTheSameElExist && index < indexOfTheSameDictEl)
                            ) {
                                const dictionaryParams = createDictionaryParams(params);
                                const data = await getDictionary({
                                    code: field.dictName,
                                    params: dictionaryParams,
                                });
                                dispatch(setDictionary({[field.dictName]: data}));
                            }
                        }
                    });
                },
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schema, dispatch, params?.wellId, params?.projectId, updateDictionaries]);

    return schema;
};

export default usePageSchema;
